import { observer } from "mobx-react-lite";
import { Link } from "react-router-dom";
import React from "react";
import { Col, FlexboxGrid, Rate, Row } from "rsuite";
import { Container, Content } from "../../style";
import {
  Paragraph,
  ParagraphBold,
  Title,
} from "../../../../components/Typography";
import styled from "styled-components";
import { Filter } from "../../../../components/Inputs/Filter";
import { useCoursePresenter } from "../../instrutor/cursos/presenter";
import { cutText } from "../../../../util/stringUtils";
const options = [
  { value: "bySurname", label: "Mais novo" },
  { value: "byIdNumber", label: "Mais Antigo" },
  { value: "byAZ", label: "A-Z" },
  { value: "byZA", label: "Z-A" },
];
const Percurso: React.FC = observer(() => {
  const {
    resetStorePurchased,
    purchasedList,
    setPurchased,
    getItemModuleId,
    resetStoreValuesItemModule,
    resetStoreValuesItemModule1,
    getItemModuleByCourseId,
  } = useCoursePresenter();
  return (
    <Container>
      <Content>
        <FlexboxGrid justify="space-between">
          <FlexboxGrid.Item colspan={12} as={Col} xs={24}>
            <Title color="#000" style={{ marginTop: 10 }}>
              Meu percurso
            </Title>
          </FlexboxGrid.Item>
          <FlexboxGrid.Item
            colspan={4}
            as={Col}
            xs={24}
            style={{ display: "flex" }}
          >
            <Filter options={options} />
          </FlexboxGrid.Item>
        </FlexboxGrid>
        <CourseContent>
          {purchasedList && (
            <Row>
              {purchasedList.map((item, i) => (
                <Col xxl={5} xl={5} lg={4} md={8} sm={12} xs={12}>
                  <CardCourse>
                    <Link
                      to={
                        Number(item?.categoria) === 1
                          ? `/dashboard/user/meu-percurso/${item.external_id}/payment`
                          : item?.lasted_view_id
                          ? `/dashboard/watch/${item.lasted_view_id}`
                          : `/dashboard/watch/`
                      }
                      title={item?.cursos?.titulo}
                      style={{ textDecoration: "none" }}
                      onClick={() => {
                        // resetStoreItemModule()
                        resetStoreValuesItemModule();
                        resetStoreValuesItemModule1();
                        resetStorePurchased();
                        setPurchased(item);
                        getItemModuleId(item.id_course as string);
                        getItemModuleByCourseId(item.id_course as string);
                      }}
                    >
                      <img src={item?.cursos?.img_url} />
                      <ParagraphBold style={{ margin: 10 }}>
                        {cutText(item?.cursos?.titulo ?? "", 70)}
                      </ParagraphBold>
                      <Paragraph
                        size="11px"
                        style={{
                          marginLeft: 10,
                          marginRight: 10,
                          marginBottom: 10,
                        }}
                      >
                        <Rate
                          defaultValue={5}
                          size="xs"
                          color="yellow"
                          readOnly
                        />
                      </Paragraph>
                      {Number(item.categoria) === 1 && (
                        <AlertPayment>
                          <Paragraph>Pendente financeiro</Paragraph>
                        </AlertPayment>
                      )}
                    </Link>
                  </CardCourse>
                </Col>
              ))}
            </Row>
          )}
        </CourseContent>
      </Content>
    </Container>
  );
});

export default Percurso;
export const CardCourse = styled.div`
  width: 100%;
  min-height: 280px;
  border: 1px solid #e0e0e0;
  background-color: #fff;
  margin-bottom: 10px;
  margin-right: 10px;
  img {
    width: 100%;
  }
`;
const AlertPayment = styled.div`
  padding: 10px 15px;
  background-color: #f5f7c1;
  border-top: 1px solid #cbcf91;
`;

const CourseContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-top: 20px;
`;
