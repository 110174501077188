import { observer } from "mobx-react-lite";
import { Route, Routes, useParams } from "react-router-dom";
import styled from "styled-components";
import React from "react";
import { useNavigate } from "react-router-dom";
import { FaCircleInfo, FaListOl } from "react-icons/fa6";
import type { MenuProps } from "antd";
import { Layout, Menu } from "antd";
import {
  Paragraph,
  ParagraphBold,
  Title,
} from "../../../../components/Typography";
import { CardBody, Container, Content } from "../../style";
import { Col, Message, Row } from "rsuite";
import { useUserStore } from "../../../../stores/userStore";
import { useAuthPresenter } from "../../../front/presenter";
import Button from "../../../../components/Buttons/Button";
const ConfirmPayment: React.FC = observer(() => {
  const { user } = useUserStore();
  const { loading, uploadComprovativoPayment } = useAuthPresenter();
  function onSelectFile(e: React.ChangeEvent<HTMLInputElement>) {
    if (e.target.files && e.target.files.length > 0) {
      uploadComprovativoPayment(e.target.files[0]);
    }
  }
  return (
    <Container>
      <Content>
        <CardBody>
          <Message type="warning" style={{ margin: 15 }}>
            <Paragraph>
              <strong>Aviso!</strong> Confirmação de pagamento via transferência
              bancária
            </Paragraph>
          </Message>
          <Paragraph size={"14px"} style={{ marginLeft: 30, marginRight: 30 }}>
            Prezado(a) <strong>{user?.name}</strong> . <br /> <br />
            Para concluir seu pagamento, pedimos que siga os seguintes passos:
            <br />
            <br />
            <strong>
              1.Realize a transferência bancária nas coordenadas enviadas por
              email .
            </strong>{" "}
            Certifique-se de inserir o valor correto e conferir os dados antes
            de finalizar a operação.
            <br />
            <br />
            <strong> 2.Envie o comprovante de pagamento</strong> para
            [e-mail/WhatsApp] com o assunto “Comprovante de Pagamento - [Seu
            Nome]” para que possamos processar sua confirmação.
            <br />
            <br />
            Assim que recebermos o comprovante, daremos continuidade à validação
            do pagamento e confirmaremos a sua transação. Caso tenha dúvidas,
            estamos à disposição para ajudá-lo!
            <br />
            <br />
            Atenciosamente,
            <br />
            <br />
            Manuel Miranda - <strong>CEO & Co-Founder</strong>
            <br />
            <br />
            <br />
            <br />
          </Paragraph>
        </CardBody>
      </Content>
    </Container>
  );
});
export default ConfirmPayment;
const ButtonUpload = styled.input.attrs({ type: "file" })`
  appearance: none;
  opacity: 0;
  width: 80%;
  height: 50px;
  cursor: pointer;
  position: absolute;
  margin-top: 10px;
`;
