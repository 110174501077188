import { observer } from "mobx-react-lite";
import ReactCrop, {
  centerCrop,
  makeAspectCrop,
  Crop,
  PixelCrop,
} from "react-image-crop";
import {
  FaFacebook,
  FaLink,
  FaLinkedin,
  FaRegBookmark,
  FaUserInjured,
  FaUserLarge,
  FaUserPen,
  FaXTwitter,
  FaYoutube,
} from "react-icons/fa6";
import React, { useRef, useState } from "react";
import { Body, ButtonGroup, CardBody, Container, Content } from "../../style";
import { useUserStore } from "../../../../stores/userStore";
import { useUserPresenter } from "../presenter";
import { useDebounceEffect } from "../../../../components/canva/useDebounceEffect";
import { canvasPreview } from "../../../../components/canva/previewCanva";
import TabEditProfile from "../../../../components/TabSimple/TabEditProfile";
function centerAspectCrop(
  mediaWidth: number,
  mediaHeight: number,
  aspect: number
) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: "%",
        width: 100,
      },
      aspect,
      mediaWidth,
      mediaHeight
    ),
    mediaWidth,
    mediaHeight
  );
}
const TO_RADIANS = Math.PI / 180;
const EditarProfile: React.FC = observer(() => {
  const { imgSrc, setImgSrc, loadding, uploadImageProfile } =
    useUserPresenter();
  const { user } = useUserStore();
  const previewCanvasRef = useRef<HTMLCanvasElement>(null);
  const [aspect] = useState<number | undefined>(10 / 10);
  const imgRef = useRef<HTMLImageElement>(null);
  const [completedCrop, setCompletedCrop] = useState<PixelCrop>();
  const [crop, setCrop] = useState<Crop>();
  const [scale] = useState(1);
  const [rotate] = useState(0);

  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        // We use canvasPreview as it's much faster than imgPreview.
        canvasPreview(
          imgRef.current,
          previewCanvasRef.current,
          completedCrop,
          scale,
          rotate
        );
      }
    },
    100,
    [completedCrop, scale, rotate]
  );
  function onSelectFile(e: React.ChangeEvent<HTMLInputElement>) {
    if (e.target.files && e.target.files.length > 0) {
      setCrop(undefined); // Makes crop preview update between images.
      const reader = new FileReader();
      reader.addEventListener("load", () =>
        setImgSrc(reader.result?.toString() || "")
      );
      reader.readAsDataURL(e.target.files[0]);
    }
  }
  function onImageLoad(e: React.SyntheticEvent<HTMLImageElement>) {
    if (aspect) {
      const { width, height } = e.currentTarget;
      setCrop(centerAspectCrop(width, height, aspect));
    }
  }
  async function uploadImage() {
    const ctx = previewCanvasRef.current
      ? previewCanvasRef.current.getContext("2d")
      : null;
    const image = imgRef.current;
    const canvas = previewCanvasRef.current;
    if (!ctx) {
      throw new Error("No 2d context");
    }
    const scaleX = image ? image?.naturalWidth / image?.width : null;
    const scaleY = image ? image?.naturalHeight / image?.height : null;
    const pixelRatio = window.devicePixelRatio;
    if (canvas && completedCrop && scaleX && scaleY && image) {
      canvas.width = Math.floor(completedCrop?.width * scaleX * pixelRatio);
      canvas.height = Math.floor(completedCrop?.height * scaleY * pixelRatio);

      ctx.scale(pixelRatio, pixelRatio);
      ctx.imageSmoothingQuality = "high";

      const cropX = completedCrop.x * scaleX;
      const cropY = completedCrop.y * scaleY;

      const rotateRads = rotate * TO_RADIANS;
      const centerX = image.naturalWidth / 2;
      const centerY = image.naturalHeight / 2;
      ctx.save();

      // 5) Move the crop origin to the canvas origin (0,0)
      ctx.translate(-cropX, -cropY);
      // 4) Move the origin to the center of the original position
      ctx.translate(centerX, centerY);
      // 3) Rotate around the origin
      ctx.rotate(rotateRads);
      // 2) Scale the image
      ctx.scale(scale, scale);
      // 1) Move the center of the image to the origin (0,0)
      ctx.translate(-centerX, -centerY);
      ctx.drawImage(
        image,
        0,
        0,
        image.naturalWidth,
        image.naturalHeight,
        0,
        0,
        image.naturalWidth,
        image.naturalHeight
      );
      if (previewCanvasRef.current) {
        previewCanvasRef.current.toBlob(
          (blob: any) => {
            const newImage = new File([blob], blob.name, { type: blob.type });
            uploadImageProfile(newImage);
          },
          "image/jpg",
          1
        );
      }
    }
  }
  return (
    <Container>
      <Content>
        <CardBody>
          <Body>
            <TabEditProfile />
          </Body>
        </CardBody>
      </Content>
    </Container>
  );
});

export default EditarProfile;
