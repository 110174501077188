import styled from "styled-components";
import { device } from "../../config/devices";
import theme from "../../theme";
interface IProps {
  color?: string;
  size?: string | number;
  font?: number;
}
export const Section = styled.section<IProps>`
  width: 100%;
  min-height: 200px;
  padding: 40px 0;
  background-color: ${({ color }) => (color ? color : "#f4f4f4")};
  @media ${device.mobileL} {
    padding: 20px 0;
  }
`;
export const ContentCourse = styled.div`
  width: 85%;
  margin: 0px auto;
  @media ${device.laptopL} {
  }
`;
export const ImageMain = styled.img<IProps>`
  width: 100%;
  @media ${device.tablet} {
  }
`;
export const FormWrapper = styled.div`
  width: 86%;
  margin: 60px auto;
  @media ${device.tablet} {
    width: 90%;
  }

  @media ${device.laptop} {
  }
`;
export const ContentText = styled.div`
  padding: 25px 20px;
  text-align: center;
  @media ${device.tablet} {
    padding: 15px;
  }

  @media ${device.laptop} {
  }
`;
export const CardCourse = styled.div`
  width: 92%;
  max-height: 470px;
  background-color: #fff;
  border-radius: 5px @media ${device.laptopL} {

  }
`;
export const FormTeacher = styled.div`
  width: 80%;
  margin: auto;
  padding: 30px 35px;
  min-height: 470px;
  border: 1px solid #e0e0e0;
  background-color: #fff;
  border-radius: 5px;
  @media ${device.laptopL} {
  }
`;
export const FormLogin = styled.div`
  width: 80%;
  margin: auto;
  padding: 30px 35px;
  min-height: 400px;
  border: 1px solid #e0e0e0;
  background-color: #fff;
  border-radius: 5px;
  @media ${device.tablet} {
    width: 100%;
    padding: 0px 0px;
  }
`;
export const FormSignUp = styled.div`
  width: 88%;
  margin: auto;
  padding: 30px 35px;
  min-height: 400px;
  border: 1px solid #e0e0e0;
  background-color: #fff;
  border-radius: 5px;
  @media ${device.tablet} {
    width: 100%;
    padding: 0px 0px;
  }
`;
export const ContentForm = styled.div`
  @media ${device.tablet} {
    width: 86%;
    margin: 40px auto;
  }
`;

export const CardImage = styled.div`
  width: 250px;
  height: 250px;
  border: 6px solid ${theme.BACKGROUND.BLUE};
  background-color: #fff;
  border-radius: 50%;
  margin: 30px auto;
  @media ${device.laptopL} {
  }
  img {
    width: 240px;
    height: 240px;
    margin: 5px 5px;
    border-radius: 50%;
  }
`;
export const ImageCardCourse = styled.img`
  width: 100%;
  @media ${device.laptopL} {
  }
`;
export const InfoCardCourse = styled.div`
  width: 100%;
  padding: 10px;
  @media ${device.laptopL} {
  }
`;
export const ButtonPayCourse = styled.button`
  padding: 6px 15px;
  background-color: #fff;
  color: ${theme.BACKGROUND.BLUE};
  border-radius: 6px;
  font-weight: bold;
  font-family: Montserrat;
  border: 2px solid ${theme.BACKGROUND.BLUE};
  margin-top: 15px;
  @media ${device.laptopL} {
  }
`;
