import { observer } from "mobx-react-lite";
import FrontLayout from "../../../layouts/FrontLayout";
import styled from "styled-components";
import { device } from "../../../config/devices";
import { ContentForm, FormSignUp, Section } from "../style";
import { Link, useNavigate, useParams } from "react-router-dom";
import React from "react";
import {
  Paragraph,
  ParagraphBold,
  Title,
} from "../../../components/Typography";
import TextInput from "../../../components/Inputs/TextInput";
import { FaLock, FaUser } from "react-icons/fa6";
import Button from "../../../components/Buttons/Button";
import theme from "../../../theme";
import { Col, Row } from "rsuite";
import loader from "../../../assets/signup.json";
import Lottie from "react-lottie";
import { useUserStore } from "../../../stores/userStore";
const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: loader,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};
const SignUpScreen: React.FC = observer(() => {
  const navigate = useNavigate();
  const { isLoggedIn, user } = useUserStore();
  React.useEffect(() => {
    if (isLoggedIn) navigate(`/dashboard/user/meu-percurso`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn]);
  return (
    <>
      <FrontLayout>
        <Section>
          <Content>
            <AuthForm>
              <Row>
                <Col
                  xl={12}
                  xxl={12}
                  lg={12}
                  md={12}
                  sm={24}
                  xs={24}
                  smHidden
                  xsHidden
                >
                  <Lottie options={defaultOptions} width="100%" />
                </Col>
                <Col xl={12} xxl={12} lg={12} md={12} sm={24} xs={24}>
                  <FormSignUp>
                    <ContentForm>
                      {" "}
                      <ParagraphBold size="19px" style={{ marginTop: 10 }}>
                        🚀 Aprenda, cresça e transforme seu futuro com a
                        Rozacademy!
                      </ParagraphBold>
                      <Paragraph
                        color="#000"
                        size="15px"
                        style={{ marginTop: 15, marginBottom: 15 }}
                      >
                        O melhor momento para investir no seu conhecimento é
                        agora. Não fique para trás enquanto o mundo evolui!
                      </Paragraph>
                      <ParagraphBold style={{ margin: 0, padding: 0 }}>
                        Nome
                      </ParagraphBold>
                      <TextInput
                        placeholder="Escrever nome :"
                        icon={
                          <FaUser
                            size={18}
                            color="#999"
                            style={{ margin: 5, marginRight: 12 }}
                          />
                        }
                      />
                      <ParagraphBold style={{ marginTop: 10, padding: 0 }}>
                        Email
                      </ParagraphBold>
                      <TextInput
                        placeholder="Email :"
                        icon={
                          <FaUser
                            size={18}
                            color="#999"
                            style={{ margin: 5, marginRight: 12 }}
                          />
                        }
                      />
                      <ParagraphBold style={{ marginTop: 10, padding: 0 }}>
                        Palavra Passe
                      </ParagraphBold>
                      <TextInput
                        placeholder="Palavra Passe :"
                        type="password"
                        icon={
                          <FaLock
                            size={18}
                            color="#999"
                            style={{ margin: 5, marginRight: 12 }}
                          />
                        }
                      />
                      <ParagraphBold style={{ marginTop: 10, padding: 0 }}>
                        Palavra Passe
                      </ParagraphBold>
                      <TextInput
                        placeholder="Palavra Passe :"
                        type="password"
                        icon={
                          <FaLock
                            size={18}
                            color="#999"
                            style={{ margin: 5, marginRight: 12 }}
                          />
                        }
                      />
                      <Button
                        label="ENTRAR AGORA"
                        color={theme.BACKGROUND.BLUE}
                      />
                    </ContentForm>
                  </FormSignUp>
                </Col>
              </Row>
            </AuthForm>
          </Content>
        </Section>
      </FrontLayout>
    </>
  );
});
export default SignUpScreen;
const Content = styled.div`
  width: 86%;
  margin: auto;
  @media ${device.laptopL} {
    width: 80%;
  }
  @media ${device.mobileL} {
    width: 96%;
  }
`;

const AuthForm = styled.div`
  width: 100%;
  margin: auto;
  @media ${device.laptopL} {
    width: 80%;
  }
  @media ${device.tablet} {
    width: 100%;
    margin: 0;
  }
`;
