import { observer } from "mobx-react-lite";
import FrontLayout from "../../../layouts/FrontLayout";
import { H1, Paragraph, ParagraphBold } from "../../../components/Typography";
import styled from "styled-components";
import { bg7, bg8 } from "../../../images";
import theme from "../../../theme";

import { ContentText, FormWrapper, ImageMain, Section } from "../style";
import { Col, Divider, Grid, Rate, Row } from "rsuite";
import {
  FaCheck,
  FaFile,
  FaGenderless,
  FaPhone,
  FaPlay,
  FaUserLarge,
  FaUsers,
} from "react-icons/fa6";
import { Link, useNavigate } from "react-router-dom";
import Button from "../../../components/Buttons/Button";
import { Image, Title } from "../training-center";
import {
  FaCheckSquare,
  FaMapMarkerAlt,
  FaRegCheckSquare,
} from "react-icons/fa";
import TextInputLarge from "../../../components/Inputs/TextInputLarge";
import { MdMail, MdSchool } from "react-icons/md";
import TextArea from "../../../components/Inputs/TextArea";

const Company: React.FC = observer(() => {
  const navigate = useNavigate();
  return (
    <>
      <FrontLayout>
        <Section style={{ backgroundColor: "#fff" }}>
          <Row>
            <Col lg={12} xl={12} xxl={24} md={24} sm={24} xs={24}>
              <ContentText>
                <ImageMain src={bg8} />
                <ParagraphBold size={"24px"} style={{ marginTop: 13 }}>
                  Mantenha sua equipe motivada e impulsione seu crescimento
                  profissional.
                </ParagraphBold>
                <Paragraph size="17px" style={{ marginTop: 15 }}>
                  No mercado atual, onde a inovação e a adaptação são essenciais
                  para o sucesso, empresas que investem no desenvolvimento
                  contínuo de seus colaboradores saem na frente. A{" "}
                  <strong>Rozacademy</strong> surge como uma solução estratégica
                  para aprimorar as habilidades da equipe, impulsionar a
                  produtividade e fortalecer a competitividade organizacional.
                </Paragraph>
                <ParagraphBold size="16px">
                  Com a Rozacademy como parceira você pode ter os seguintes
                  benefícios:
                </ParagraphBold>
                <Paragraph size="16px">
                  <FaRegCheckSquare color={theme.BACKGROUND.BLUE} />{" "}
                  <strong>Desenvolvimento Contínuo -</strong> Acesso a
                  treinamentos atualizados, garantindo que os colaboradores
                  acompanhem as tendências do mercado.
                </Paragraph>
                <Paragraph size="16px">
                  <FaRegCheckSquare color={theme.BACKGROUND.BLUE} />{" "}
                  <strong>Flexibilidade e Acessibilidade -</strong>Funcionários
                  podem aprender de qualquer lugar e a qualquer momento, sem
                  comprometer a rotina de trabalho.
                </Paragraph>
                <Paragraph size="16px">
                  <FaRegCheckSquare color={theme.BACKGROUND.BLUE} />{" "}
                  <strong>Otimização de Custos - </strong>Redução de despesas
                  com treinamentos presenciais, deslocamentos e materiais
                  impressos.
                </Paragraph>
                <Paragraph size="16px">
                  <FaRegCheckSquare color={theme.BACKGROUND.BLUE} />{" "}
                  <strong>Aumento da Produtividade - </strong> Profissionais
                  capacitados desempenham suas funções com mais eficiência e
                  confiança.
                </Paragraph>
                <Paragraph size="16px">
                  <FaRegCheckSquare color={theme.BACKGROUND.BLUE} />{" "}
                  <strong> Desenvolvimento de Soft e Hard Skills - </strong>
                  Além de habilidades técnicas, treinamentos online podem
                  aprimorar competências comportamentais, como liderança,
                  comunicação e gestão de tempo.
                </Paragraph>
                <Paragraph size="16px">
                  <FaRegCheckSquare color={theme.BACKGROUND.BLUE} />{" "}
                  <strong>Monitoramento e Personalização - </strong>Relatórios
                  de desempenho permitem acompanhar o progresso dos funcionários
                  e adaptar os conteúdos conforme as necessidades da empresa.{" "}
                </Paragraph>
                <Paragraph size="16px">
                  Ao adotar a <strong>Rozacademy</strong> como parceiro, a
                  organização transforma a capacitação em um diferencial
                  competitivo, impulsionando a inovação e garantindo uma equipe
                  mais preparada para os desafios do mercado. A tecnologia se
                  torna uma aliada na construção de uma cultura de aprendizado
                  contínuo, tornando o conhecimento acessível a todos.
                </Paragraph>
                <ParagraphBold size="16px">
                  🚀 Invista no crescimento da sua empresa com a{" "}
                  <strong>Rozacademy</strong>! Capacite seus colaboradores e
                  alcance novos patamares de inovação e excelência.
                </ParagraphBold>
              </ContentText>
            </Col>
            <Col
              lg={12}
              xl={12}
              xxl={12}
              md={24}
              sm={24}
              xs={24}
              style={{ textAlign: "left" }}
            >
              <FormWrapper>
                <ParagraphBold size={"20px"}>
                  Ao adotar a Rozacademy, sua empresa ganha uma ferramenta
                  poderosa para transformar o aprendizado corporativo em um
                  diferencial competitivo. Com uma plataforma intuitiva e
                  moderna, é possível oferecer treinamentos personalizados,
                  acessíveis a qualquer momento e de qualquer lugar.
                </ParagraphBold>
                <Paragraph size="15px" style={{ textAlign: "left" }}>
                  A nossa solução não é apenas uma plataforma de ensino, mas uma
                  ferramenta estratégica que transforma a cultura
                  organizacional, promovendo aprendizado contínuo e preparando
                  sua equipe para os desafios do futuro.
                </Paragraph>

                <Row>
                  <Col lg={24} xl={24} xxl={24} md={24} sm={24} xs={24}>
                    <ParagraphBold size={"15px"}>
                      Solicite a adesão ao serviço agora e descubra como a
                      Rozacademy pode impulsionar sua organização.
                    </ParagraphBold>
                    <TextInputLarge
                      placeholder="Escrever nome completo :"
                      hasLabel
                      label="Nome completo"
                      icon={
                        <FaUserLarge
                          color="#999"
                          size={20}
                          style={{ padding: "15px 14px" }}
                        />
                      }
                    />
                  </Col>
                  <Col lg={24} xl={24} xxl={24} md={24} sm={24} xs={24}>
                    <TextInputLarge
                      placeholder="Escrever Email :"
                      hasLabel
                      label="Email"
                      icon={
                        <MdMail
                          color="#999"
                          size={22}
                          style={{ padding: "15px 14px" }}
                        />
                      }
                    />
                  </Col>
                  <Col lg={24} xl={24} xxl={24} md={24} sm={24} xs={24}>
                    <TextInputLarge
                      placeholder="Escrever nº de telefone:"
                      hasLabel
                      label="Nº de  telefone"
                      icon={
                        <FaPhone
                          color="#999"
                          size={20}
                          style={{ padding: "15px 10px" }}
                        />
                      }
                    />
                  </Col>
                  <Col
                    style={{ marginTop: 25 }}
                    lg={24}
                    xl={24}
                    xxl={24}
                    md={24}
                    sm={24}
                    xs={24}
                  >
                    <TextInputLarge
                      placeholder="Escrever nome :"
                      hasLabel
                      label="Nome da organização "
                      icon={
                        <MdSchool
                          color="#999"
                          size={22}
                          style={{ padding: "15px 14px" }}
                        />
                      }
                    />
                  </Col>
                  <Col lg={24} xl={24} xxl={24} md={24} sm={24} xs={24}>
                    <TextInputLarge
                      placeholder="Escrever  nº de pessoas :"
                      hasLabel
                      type="number"
                      icon={
                        <FaUsers
                          color="#999"
                          size={19}
                          style={{ padding: "13px 10px" }}
                        />
                      }
                      label="Número de pessoas que serão treinadas *"
                    />
                  </Col>
                  <Col lg={24} xl={24} xxl={24} md={24} sm={24} xs={24}>
                    <TextInputLarge
                      placeholder="Escrever NIF:"
                      hasLabel
                      icon={
                        <FaFile
                          color="#999"
                          size={19}
                          style={{ padding: "13px 10px" }}
                        />
                      }
                      label="NIF"
                    />
                  </Col>
                  <Col lg={24} xl={24} xxl={24} md={24} sm={24} xs={24}>
                    <TextInputLarge
                      placeholder="Escrever endereço:"
                      hasLabel
                      icon={
                        <FaMapMarkerAlt
                          color="#999"
                          size={20}
                          style={{ padding: "12px 10px" }}
                        />
                      }
                      label="Endereço"
                    />
                  </Col>
                  <Col
                    lg={24}
                    xl={24}
                    xxl={24}
                    md={24}
                    sm={24}
                    xs={24}
                    style={{ marginTop: 15 }}
                  >
                    <TextArea placeholder="Quais são as necessidades de treinamento da sua organização ?" />
                  </Col>
                  <Col lg={24} xl={24} xxl={24} md={24} sm={24} xs={24}>
                    <Button
                      color={theme.BACKGROUND.BLUE}
                      label="ENVIAR PEDIDO"
                    />
                  </Col>
                </Row>
              </FormWrapper>
            </Col>
          </Row>
        </Section>
      </FrontLayout>
    </>
  );
});
export default Company;
interface IProps {
  backgroundImage?: string;
}
