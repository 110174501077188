import { inject, injectable } from "inversify";
import { makeAutoObservable } from "mobx";
import { ChangeEvent } from "react";
import container from "../../../stores/ioc";
import { useClassStore } from "../../../util/useClassStore";
import NavigationStore from "../../../stores/navigationStore";
import SupabaseGateway from "../../../gateways/SupabaseGateway";
import UIStore from "../../../stores/uiStore";
import UserStore from "../../../stores/userStore";
import PurchasedCourseStore from "../../../stores/purchasedCourseStore";
import CourseStore from "../../../stores/courseStore";
@injectable()
class UserPresenter {
  [key: string]: string | undefined | any;
  @inject(SupabaseGateway) private supabaseGateway!: SupabaseGateway;
  @inject(UIStore) ui!: UIStore;
  @inject(UserStore) private userStore!: UserStore;
  @inject(CourseStore) private courseStore!: CourseStore;
  @inject(PurchasedCourseStore) private purchasedStore!: PurchasedCourseStore;
  email: string = "";
  password: string = "";
  new_password: string = "";
  confirm_password: string = "";
  loadding = false;
  imgSrc = "";
  purchased!: IPurchasedCourse | undefined;
  constructor() {
    makeAutoObservable(this);
  }
  setPurchased = (purchased: IPurchasedCourse) => {
    this.purchased = purchased;
  };
  setFormValue = (e: ChangeEvent<HTMLInputElement>) => {
    this[e.target.name] = e.target.value;
  };
  resetStorePurchased = () => {
    this.purchased = undefined;
  };
  setVideoId = (value: string) => {
    this.purchased = { ...this.purchased, lasted_view_id: value };
  };
  updateLastVideoViewed = async (lasted_view_id: string) => {
    try {
      if (this.purchased) {
        this.purchased.lasted_view_id = lasted_view_id as string;
      }
      await this.purchasedStore.updatePurchased(
        this.purchased as IPurchasedCourse
      );
    } catch (error) {}
  };
  activateInstrutor = async () => {
    try {
      this.loadding = true;
      this.user.categoria = 1;
      await this.userStore.activateInstrutor(this.user);
    } catch (error) {
    } finally {
      this.loadding = false;
    }
  };
  updatePurchased = async () => {
    try {
      delete this.purchased?.cursos;
      await this.purchasedStore.updatePurchased(
        this.purchased as IPurchasedCourse
      );
    } catch (error) {}
  };
  setImgSrc = (value: string) => {
    this.imgSrc = value;
  };
  get user() {
    return this.userStore.user;
  }
  ChangePasswordUser = async () => {
    try {
      await this.userStore.ChangePasswordUser(this.password, this.new_password);
    } catch (error) {}
  };
  uploadImageProfile = async (img: File) => {
    try {
      this.loadding = true;
      await this.userStore.uploadImage(img);
      this.imgSrc = "";
    } catch (error) {
    } finally {
      this.loadding = false;
    }
  };
  getPurchasedOneByUser = async (course: string, user_id: string) => {
    try {
      const purchased = await this.purchasedStore.getPurchasedOneByUser(
        course,
        user_id
      );
      this.purchased = purchased?.data as IPurchasedCourse;
      console.log(purchased);
    } catch (error) {}
  };
  get purchasedList(): IPurchasedCourse[] {
    return this.purchasedStore.purchasedList;
  }
}
export const useUserPresenter = () =>
  useClassStore<UserPresenter>(container.get(UserPresenter));

export default UserPresenter;
