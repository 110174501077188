import styled from "styled-components";
import { device } from "../../config/devices";

export const Content = styled.div`
  width: 90%;
  margin: auto;
  @media ${device.laptopL} {
    width: 80%;
  }
  @media ${device.mobileL} {
    width: 98%;
  }
`;
export const Container = styled.div`
  width: 100%;
  justify-content: center;
  margin-top: 20px;
`;
export const CardMoreInfo = styled.div`
  width: 100%;
  min-height: 120px;
  background-color: #fff;
  padding: 15px 0;
`;
export const GridAula = styled.div`
  width: 100%;
  margin-top: 10px;
  background-color: #fff;
  padding: 15px 0;
  border: 1px solid #e0e0e0;
  border-radius: 0px;
`;
export const ContentAccordion = styled.div`
  width: 100%;
  margin-top: 0px;
  @media ${device.mobileL} {
  }
`;
export const TabCourseDetail = styled.div`
  width: 100%;
  min-height: 120px;
  padding: 15px 0px;
  background-color: #fff;
`;
export const CardBody = styled.div`
  width: 100%;
  background-color: #fff;
  border: 1px solid #e9e9e9;
  justify-content: center;
  margin-top: 20px;
`;
export const FooterDashboard = styled.div`
  width: 90%;
  background-color: #fff;
  min-height: 60px;
  margin: 20px auto;
  border: 1px solid #e9e9e9;
  justify-content: center;
  @media ${device.laptopL} {
    width: 80%;
  }
`;
export const Body = styled.div`
  padding: 30px;
`;
export const ButtonGroup = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 101%;
  margin-top: 15px;
`;
