import React, { ReactElement, useState } from "react";
import styled from "styled-components";
import TabList from "./TabList";
import TabItem from "./TabItem";
import { Paragraph, ParagraphBold } from "../Typography";
import { Modals } from "../../components";
import { observer } from "mobx-react-lite";
import { FaInfoCircle, FaThList } from "react-icons/fa";
import { Accordion, Col, FlexboxGrid, Row, Stack } from "rsuite";
import { useCoursePresenter } from "../../pages/dashboard/instrutor/cursos/presenter";
import { ContentAccordion, GridAula } from "../../pages/dashboard/style";
import { FaFilm, FaPlay, FaRegClock } from "react-icons/fa6";
import theme from "../../theme";
import { MdOutlineOndemandVideo } from "react-icons/md";
interface HeaderProps {
  i: number;
  title: string;
  time: string;
  item_module: number;
}
const Header = (props: HeaderProps) => {
  const { i, title, time, item_module, ...rest } = props;
  return (
    <Stack {...rest} spacing={10} alignItems="flex-start">
      <Stack spacing={2} direction="column" alignItems="flex-start">
        <ParagraphBold size="13px">
          {i}
          {" - "}
          {title}
        </ParagraphBold>
        <Paragraph color="#555">
          {item_module} Aulas {" - "} {time}
        </Paragraph>
      </Stack>
    </Stack>
  );
};
const TabCourse = observer(() => {
  const {
    moduleList,
    getTotalTimeCourse,
    getAlltimeCourse,
    time_completed_course,
    course,
    getQtdAula,
  } = useCoursePresenter();
  return (
    <>
      <TabList activeTabIndex={0}>
        <TabItem label="Grade curricular " icon={<FaThList />}>
          <ParagraphBold size="14px" style={{ paddingTop: 15 }}>
            Grade curricular do curso
          </ParagraphBold>
          <Paragraph size="13px">
            Lista organizada dos temas e conteúdos abordados ao longo do curso,
            estruturados para promover um aprendizado progressivo .
          </Paragraph>
          {moduleList && (
            <Accordion defaultActiveKey={1}>
              {moduleList.map((module, i) => (
                <Accordion.Panel
                  header={
                    <Header
                      i={i + 1}
                      title={module.title as string}
                      time={getAlltimeCourse(module as IModule)}
                      item_module={module.items_module?.length as number}
                    />
                  }
                  eventKey={i + 1}
                >
                  {module.items_module && (
                    <ContentAccordion>
                      {module?.items_module.map((item1, index) => (
                        <GridAula>
                          <FlexboxGrid
                            justify="space-between"
                            style={{ marginRight: 10 }}
                          >
                            <FlexboxGrid.Item
                              as={Col}
                              colspan={22}
                              xxl={22}
                              xl={21}
                              lg={21}
                              md={20}
                              sm={20}
                              xs={20}
                            >
                              <Paragraph size="13px" style={{ marginLeft: 20 }}>
                                <strong>Aula {index + 1} - </strong>
                                {item1.titulo}
                              </Paragraph>
                            </FlexboxGrid.Item>
                            <FlexboxGrid.Item
                              colspan={2}
                              as={Col}
                              xxl={2}
                              xl={3}
                              xs={4}
                            >
                              {i == 0 && index == 0 ? (
                                <Paragraph
                                  style={{
                                    textAlign: "right",
                                  }}
                                >
                                  <FaPlay
                                    size={16}
                                    color={theme.BACKGROUND.GREEN}
                                  />
                                </Paragraph>
                              ) : (
                                <Paragraph
                                  color="#999"
                                  style={{
                                    textAlign: "right",
                                  }}
                                >
                                  {item1.time}
                                </Paragraph>
                              )}
                            </FlexboxGrid.Item>
                          </FlexboxGrid>
                        </GridAula>
                      ))}
                    </ContentAccordion>
                  )}
                </Accordion.Panel>
              ))}
            </Accordion>
          )}
        </TabItem>
        <TabItem label="Informações Adicionais" icon={<FaInfoCircle />}>
          <Row>
            <Col
              lg={24}
              xl={24}
              xxl={24}
              md={24}
              sm={24}
              xs={24}
              style={{ margin: "20px 0" }}
            >
              <FlexboxGrid justify="space-around">
                <FlexboxGrid.Item
                  as={Col}
                  colspan={8}
                  xxl={8}
                  xl={7}
                  lg={8}
                  md={8}
                  sm={8}
                  xs={8}
                >
                  {moduleList && (
                    <Paragraph size="14px">
                      <MdOutlineOndemandVideo
                        color="#999"
                        style={{ marginRight: 10 }}
                      />{" "}
                      <strong>{moduleList.length}</strong> Módulos
                    </Paragraph>
                  )}
                </FlexboxGrid.Item>
                <FlexboxGrid.Item
                  as={Col}
                  colspan={10}
                  xxl={10}
                  xl={8}
                  lg={8}
                  md={7}
                  sm={7}
                  xs={7}
                >
                  {moduleList && (
                    <Paragraph size="14px">
                      <FaFilm color="#999" style={{ marginRight: 10 }} />{" "}
                      <strong>{getQtdAula(moduleList)}</strong> Aulas
                    </Paragraph>
                  )}
                </FlexboxGrid.Item>
                <FlexboxGrid.Item
                  as={Col}
                  colspan={6}
                  xxl={6}
                  xl={8}
                  lg={6}
                  md={9}
                  sm={9}
                  xs={9}
                >
                  <Paragraph>
                    <FaRegClock color="#999" style={{ marginRight: 10 }} />{" "}
                    {getTotalTimeCourse()}
                  </Paragraph>
                </FlexboxGrid.Item>
              </FlexboxGrid>
            </Col>
          </Row>
          {course?.para_quem && (
            <>
              <ParagraphBold size={"14px"}>
                Para quem desejas compartilhar o conhecimento ?
              </ParagraphBold>
              <Paragraph size={"14px"}>{course.para_quem}</Paragraph>
            </>
          )}
          {course?.ensinado && (
            <>
              <ParagraphBold size={"14px"}>
                O que é essencialmente ensinado em seu curso?
              </ParagraphBold>
              <Paragraph size={"14px"}>{course.ensinado}</Paragraph>
            </>
          )}
        </TabItem>
      </TabList>
      <Modals.AddQuetionModal />
    </>
  );
});
export default TabCourse;
const Tabs = styled.nav``;
const Quetions = styled.nav`
  width: 100%;
`;
const Lesson = styled.p`
  padding: 10px 15px;
  background-color: #f0f0f0;
`;
const TabListUl = styled.ul``;
