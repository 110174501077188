import { observer } from "mobx-react-lite";
import FrontLayout from "../../../layouts/FrontLayout";
import { H1, Paragraph, ParagraphBold } from "../../../components/Typography";
import styled from "styled-components";
import { bg, bg10, bg11, instrutor, main } from "../../../images";
import theme from "../../../theme";

import {
  FaChalkboardTeacher,
  FaDesktop,
  FaMapMarkerAlt,
  FaRegCheckSquare,
} from "react-icons/fa";
import { ContentText, FormWrapper, Section } from "../style";
import Carousel from "react-multi-carousel";
import { useCoursePresenter } from "../../dashboard/instrutor/cursos/presenter";
import { Col, Divider, Grid, Rate, Row } from "rsuite";
import {
  FaCartShopping,
  FaCheck,
  FaComputer,
  FaFile,
  FaPhone,
  FaUserLarge,
} from "react-icons/fa6";
import { Link, useNavigate } from "react-router-dom";
import Button from "../../../components/Buttons/Button";
import { Image, ImageMain, TextMargin, Title } from "../training-center";
import TextInputLarge from "../../../components/Inputs/TextInputLarge";
import { MdMail, MdSchool } from "react-icons/md";

const Universidade: React.FC = observer(() => {
  const { courseList } = useCoursePresenter();
  const navigate = useNavigate();
  return (
    <>
      <FrontLayout>
        <Section style={{ margin: 0, padding: 0, backgroundColor: "#fff" }}>
          <Row>
            <Col
              lg={12}
              xl={12}
              xxl={24}
              md={24}
              sm={24}
              xs={24}
              style={{ padding: 10 }}
            >
              <ContentText>
                <Title color="#333" style={{ textAlign: "left" }}>
                  Transforme sua universidade ou instituição de ensino superior
                  com a Rozacademy .
                </Title>
                <Paragraph style={{ marginTop: 10 }} size="15px">
                  No cenário educacional atual, a transformação digital é
                  essencial para que universidades se mantenham competitivas e
                  ofereçam uma experiência de ensino moderna e acessível. Com a
                  Rozacademy, sua instituição ganha flexibilidade, eficiência e
                  inovação, proporcionando um ambiente dinâmico e interativo
                  para alunos e docentes.
                </Paragraph>
                <Paragraph style={{ marginTop: 10 }} size="15px">
                  <strong>
                    Com a Rozacademy como parceira você pode ter os seguintes
                    benefícios:
                  </strong>
                </Paragraph>
                <Paragraph size="15px">
                  <FaRegCheckSquare color={theme.BACKGROUND.BLUE} />{" "}
                  <strong>Acessibilidade e Inclusão –</strong>
                  Permita que alunos acessem conteúdos a qualquer hora e lugar,
                  garantindo oportunidades iguais de aprendizado.
                </Paragraph>
                <Paragraph size="15px">
                  <FaRegCheckSquare color={theme.BACKGROUND.BLUE} />{" "}
                  <strong>Engajamento Aprimorado – </strong>
                  Recursos interativos, videoaulas e fóruns colaborativos
                  aumentam a participação e o desempenho acadêmico.
                </Paragraph>
                <Paragraph size="15px">
                  <FaRegCheckSquare color={theme.BACKGROUND.BLUE} />{" "}
                  <strong>Otimização de Recursos –</strong>
                  Reduza custos com infraestrutura física, materiais impressos e
                  otimização de tempo para professores e gestores.
                </Paragraph>
                <Paragraph size="15px">
                  <FaRegCheckSquare color={theme.BACKGROUND.BLUE} />{" "}
                  <strong> Personalização do Ensino – </strong>
                  Adapte os conteúdos conforme o ritmo e as necessidades de cada
                  estudante, melhorando a retenção do conhecimento.
                </Paragraph>
                <Paragraph size="15px">
                  <FaRegCheckSquare color={theme.BACKGROUND.BLUE} />{" "}
                  <strong> Gestão Inteligente – </strong>
                  Relatórios e análises de desempenho facilitam o acompanhamento
                  acadêmico e a tomada de decisões estratégicas.
                </Paragraph>
                <Paragraph size="15px">
                  Ao investir na <strong>{"Rozacademy"}</strong>, sua
                  universidade expande suas fronteiras, podendo atender a um
                  público maior, aumentar a captação de alunos e fortalecer sua
                  reputação no mercado educacional. Além disso, a digitalização
                  do ensino prepara os estudantes para um mundo cada vez mais
                  tecnológico e conectado. <br />
                  <br />
                  <strong>
                    Não fique para trás! Leve sua instituição para o futuro da
                    educação. 🚀
                  </strong>
                </Paragraph>
              </ContentText>
            </Col>
            <Col lg={12} xl={12} xxl={24} md={24} sm={24} xs={24}>
              <FormWrapper>
                <Row>
                  <Col lg={24} xl={24} xxl={24} md={12} sm={24} xs={24}>
                    <ParagraphBold size={"15px"}>
                      Solicite a adesão da sua universidade ou instituto
                      superior e transforme a educação com inovação digital!
                    </ParagraphBold>
                    <TextInputLarge
                      placeholder="Escrever nome completo :"
                      hasLabel
                      label="Nome completo"
                      icon={
                        <FaUserLarge
                          color="#999"
                          size={26}
                          style={{ padding: "10px 14px" }}
                        />
                      }
                    />
                  </Col>

                  <Col lg={24} xl={24} xxl={24} md={24} sm={24} xs={24}>
                    <TextInputLarge
                      placeholder="Escrever Email :"
                      hasLabel
                      label="Email"
                      icon={
                        <MdMail
                          color="#999"
                          size={29}
                          style={{ padding: "10px 14px" }}
                        />
                      }
                    />
                  </Col>
                  <Col lg={24} xl={24} xxl={24} md={24} sm={24} xs={24}>
                    <TextInputLarge
                      placeholder="Escrever nº de telefone:"
                      hasLabel
                      label="Nº de  telefone"
                      icon={
                        <FaPhone
                          color="#999"
                          size={23}
                          style={{ padding: "10px 10px" }}
                        />
                      }
                    />
                  </Col>
                  <Col
                    style={{ marginTop: 25 }}
                    lg={24}
                    xl={24}
                    xxl={24}
                    md={24}
                    sm={24}
                    xs={24}
                  >
                    <TextInputLarge
                      placeholder="Escrever nome :"
                      hasLabel
                      label="Nome de universidade ou instituto superior"
                      icon={
                        <MdSchool
                          color="#999"
                          size={26}
                          style={{ padding: "10px 14px" }}
                        />
                      }
                    />
                  </Col>
                  <Col
                    style={{ marginTop: 0 }}
                    lg={12}
                    xl={12}
                    xxl={12}
                    md={12}
                    sm={24}
                    xs={24}
                  >
                    <TextInputLarge
                      placeholder="Escrever NIF:"
                      hasLabel
                      icon={
                        <FaFile
                          color="#999"
                          size={23}
                          style={{ padding: "10px 10px" }}
                        />
                      }
                      label="NIF"
                    />
                  </Col>

                  <Col lg={12} xl={12} xxl={12} md={12} sm={24} xs={24}>
                    <TextInputLarge
                      placeholder="Escrever endereço:"
                      hasLabel
                      icon={
                        <FaMapMarkerAlt
                          color="#999"
                          size={23}
                          style={{ padding: "10px 10px" }}
                        />
                      }
                      label="Endereço"
                    />
                  </Col>
                  <Col lg={24} xl={24} xxl={24} md={24} sm={24} xs={24}>
                    <Button
                      color={theme.BACKGROUND.BLUE}
                      label="ENVIAR PEDIDO"
                    />
                  </Col>
                </Row>
              </FormWrapper>
            </Col>
          </Row>
        </Section>
      </FrontLayout>
    </>
  );
});
export default Universidade;
interface IProps {
  backgroundImage?: string;
}
