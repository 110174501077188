import { inject, injectable } from "inversify";
import { makeAutoObservable, runInAction } from "mobx";
import { makePersistable } from "mobx-persist-store";

import SupabaseGateway from "../gateways/SupabaseGateway";
import { Tables } from "../types/enums";
import { useClassStore } from "../util/useClassStore";
import container from "./ioc";
import UIStore, { Drawers as Drawer, AlertType } from "./uiStore";
import { uuid } from "uuidv4";

@injectable()
class UserStore {
  @inject(SupabaseGateway) private supabaseGateway!: SupabaseGateway;
  @inject(UIStore) ui!: UIStore;
  user!: IUser;
  signUpSuccess = false;
  isLoggedIn: boolean = JSON.parse(
    localStorage.getItem("@isLoggedIn") || "false"
  );

  constructor() {
    makeAutoObservable(this);

    makePersistable(this, {
      name: "@user",
      properties: ["user"],
      storage: window.localStorage,
    });
  }

  login = async (email: string, password: string) => {
    try {
      const { data, error } =
        await this.supabaseGateway.sbClient.auth.signInWithPassword({
          email,
          password,
        });
      const { session, user } = data;
      if (error) {
        throw new Error(error.message);
      }

      await this.getUserData(user?.id as string);

      runInAction(() => {
        this.user = {
          ...this.user,
          email: user?.email,
        };
      });
      this.setIsLoggedIn();
      this.ui.setDrawer(Drawer.LOGIN_DRAWER, false);
      this.ui.setDrawer(Drawer.MENU_MOBILE_DRAWER, false);
    } catch (error: any) {
      this.ui.showAlert(error.message, AlertType.error);
      // todo: add sentry after app release...
    }
  };
  init = async () => {
    const { data, error } =
      await this.supabaseGateway?.sbClient?.auth.getSession();
    this.isLoggedIn = !!data?.session?.access_token;
    console.log(data);
    localStorage.setItem("@isLoggedIn", `${!!data?.session?.access_token}`);
  };

  setIsLoggedIn = () => {
    this.isLoggedIn = true;
    localStorage.setItem("@isLoggedIn", `true`);
  };

  getUserData = async (id: string) => {
    const { data, error } = await this.supabaseGateway.getOneById(
      Tables.users,
      `*`,
      id,
      "uuid"
    );
    runInAction(() => {
      this.user = { ...this.user, ...(data as IUser) };
    });
  };
  ChangePasswordUser = async (password: string, new_password: string) => {
    try {
      console.log(await this.supabaseGateway.sbClient.auth.getUser());
    } catch (error) {}
  };
  ChangeEmailUser = async (email: string) => {
    try {
      const { data, error } =
        await this.supabaseGateway.sbClient.auth.updateUser({ email: email });
      this.ui.showAlert("Email alterado com sucesso...", AlertType.success);
    } catch (error) {}
  };
  signUp = async (name: string, email: string, password: string) => {
    try {
      let { data, error } = await this.supabaseGateway.sbClient.auth.signUp({
        email: email!,
        password: password!,
      });
      if (error) {
        throw new Error(error.message);
      }
      await this.saveProfileData({ name, uuid: data.user?.id });
      await this.login(email, password);
      this.ui.showAlert(
        "A sua conta foi criada com sucesso...",
        AlertType.success
      );

      this.ui.setDrawer(Drawer.SIGNUP_DRAWER, false);
      this.ui.setDrawer(Drawer.MENU_MOBILE_DRAWER, false);
    } catch (error: any) {
      this.ui.showAlert(error.message, AlertType.error);
    }
  };
  uploadImage = async (image: File) => {
    try {
      const { publicUrl } = await this.supabaseGateway.uploadFile<IUser>(
        image,
        "ficheiros"
      );
      this.user.img_url = publicUrl;
      const { error } = await this.supabaseGateway.updateTable<{
        img_url: string;
      }>(Tables.users, { img_url: this.user.img_url }, { id: this.user.id });
      this.getUserData(this.user.uuid as string);
      this.ui.showAlert("Imagem alterada com sucesso ...", AlertType.success);
    } catch (error) {}
  };
  activateInstrutor = async (user: IUser) => {
    try {
      delete user.email;
      const { error } = await this.supabaseGateway.updateTable<IUser>(
        Tables.users,
        user,
        { id: user.id }
      );
      console.log(error);
      this.ui.showAlert(
        "🎉 Parabéns! Sua conta de instrutor foi ativada com sucesso! 🚀",
        AlertType.success
      );
    } catch (error: any) {
      this.ui.showAlert(error.message, AlertType.error);
    }
  };
  saveProfileData = async (data: IUser) => {
    try {
      const { name, uuid, email, categoria, phone, profissao, password } = data;

      const { error } = await this.supabaseGateway.sbClient.auth.updateUser({
        password,
      });
      if (error) {
        throw new Error(error.message);
      }

      const { error: insertError } =
        await this.supabaseGateway.insertToTable<IUser>(Tables.users, {
          name,
          uuid: uuid,
          is_active: true,
        });

      if (insertError) {
        this.ui.showAlert(
          "Verifique os campos inseridos, caso esta mensagem presista contacte a nossa equipa.",
          AlertType.error
        );
        return;
      }

      //await this.getUserData(this.user.id)

      //this.ui.showAlert('Bem-vindo', AlertType.success)
    } catch (error) {
      //this.ui.showAlert(error.message, AlertType.error)
    }
  };
}

export const useUserStore = () =>
  useClassStore<UserStore>(container.get(UserStore));

export default UserStore;
