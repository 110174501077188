import React, { ReactElement, useState } from "react";
import styled from "styled-components";
import TabList from "./TabList";
import TabItem from "./TabItem";
import { Avatar, Col, FlexboxGrid, HStack, List, Row } from "rsuite";
import { Paragraph, ParagraphBold } from "../Typography";
import { FaListOl, FaVideo } from "react-icons/fa6";
import { Modals } from "../../components";
import { observer } from "mobx-react-lite";
import { MdHelp, MdHelpCenter } from "react-icons/md";
import loader from "../../assets/teach (2).json";
import loader1 from "../../assets/video.json";
import loader2 from "../../assets/help.json";
import Lottie from "react-lottie";
const defaultOptions1 = {
  loop: true,
  autoplay: true,
  animationData: loader,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};
const defaultOptions2 = {
  loop: true,
  autoplay: true,
  animationData: loader1,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};
const defaultOptions3 = {
  loop: true,
  autoplay: true,
  animationData: loader2,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};
const TabTeach = observer(() => {
  return (
    <>
      <TabList activeTabIndex={0}>
        <TabItem
          label="Apoio na criação da grade curricular"
          icon={<FaListOl />}
        >
          <Row>
            <Col
              lg={12}
              xl={12}
              xxl={12}
              md={24}
              sm={24}
              style={{ padding: 0, margin: 0 }}
            >
              <Lottie options={defaultOptions1} width="100%" />
            </Col>
            <Col
              lg={12}
              xl={12}
              xxl={12}
              md={24}
              sm={24}
              style={{ padding: 0, margin: 0 }}
            >
              <ParagraphBold size="16px">
                Apoio na criação da grade curricular
              </ParagraphBold>
              <Paragraph size="15px">
                Construir um curso eficaz vai muito além de reunir conteúdos e
                gravar aulas. Na <strong>Rozacademy</strong>, oferecemos suporte
                especializado para estruturar sua grade curricular de forma
                lógica, envolvente e alinhada às melhores práticas pedagógicas.
                <br />
                <br />
                Nossa equipe auxilia na definição de módulos, sequenciamento de
                temas, divisão de cargas horárias e escolha de metodologias que
                potencializam o aprendizado. Além disso, ajudamos a identificar
                os principais objetivos do curso, garantindo que cada aula
                entregue valor real aos alunos.
                <br />
                <br />
                Se necessário, orientamos na criação de materiais
                complementares, atividades interativas e avaliações que reforcem
                o conteúdo ensinado. Tudo isso para que você tenha um curso bem
                planejado, didaticamente eficiente e capaz de engajar seus
                alunos do início ao fim.{" "}
                <strong>
                  Com a Rozacademy, você não apenas ensina, mas transforma
                  conhecimento em uma experiência de aprendizado impactante e
                  inesquecível! 🚀
                </strong>
              </Paragraph>
            </Col>
          </Row>
        </TabItem>
        <TabItem label="Tecnologia de ponta para gravação" icon={<FaVideo />}>
          <Row>
            <Col
              lg={12}
              xl={12}
              xxl={12}
              md={24}
              sm={24}
              style={{ padding: 0, margin: 0 }}
            >
              <Lottie options={defaultOptions2} width="100%" />
            </Col>{" "}
            <Col
              lg={12}
              xl={12}
              xxl={12}
              md={24}
              sm={24}
              style={{ padding: 0, margin: 0 }}
            >
              <ParagraphBold size="16px">
                Tecnologia de ponta para gravação
              </ParagraphBold>
              <Paragraph size="15px">
                Na <strong>Rozacademy</strong>, sabemos que a qualidade do
                conteúdo visual e sonoro é essencial para o sucesso de um curso
                online. Por isso, disponibilizamos equipamentos de alto padrão
                para garantir que suas aulas sejam gravadas com nitidez, som
                cristalino e uma produção profissional.
                <br />
                <br />
                Nossa infraestrutura conta com câmeras de alta resolução,
                microfones de captação clara, iluminação profissional e estúdios
                equipados, permitindo que você grave seus conteúdos com a melhor
                qualidade possível. Além disso, oferecemos suporte técnico para
                auxiliar na configuração dos equipamentos e otimização do
                ambiente de gravação, garantindo que cada aula tenha um
                resultado impecável.
                <br />
                <br />
                Seja você um professor experiente ou alguém que está dando os
                primeiros passos no ensino digital, nossa equipe estará ao seu
                lado para orientá-lo em cada detalhe – desde a escolha do melhor
                cenário e enquadramento até ajustes de áudio e vídeo que elevam
                a experiência dos alunos.
                <br />
                <strong>
                  Com a RozAcademy, você não precisa se preocupar com questões
                  técnicas. Nossa tecnologia está a seu dispor para que você se
                  concentre no que faz de melhor: ensinar e transformar vidas!
                  🚀
                </strong>
              </Paragraph>
            </Col>
          </Row>
        </TabItem>
        <TabItem label="Suporte técnico completo " icon={<MdHelp />}>
          <Row>
            <Col
              lg={12}
              xl={12}
              xxl={12}
              md={24}
              sm={24}
              style={{ padding: 0, margin: 0 }}
            >
              <Lottie options={defaultOptions3} width="100%" />
            </Col>
            <Col
              lg={12}
              xl={12}
              xxl={12}
              md={24}
              sm={24}
              style={{ padding: 0, margin: 0 }}
            >
              <ParagraphBold size="16px">
                Suporte técnico e estratégias de visibilidade para maximizar seu
                sucesso .
              </ParagraphBold>
              <Paragraph size="15px">
                Oferecemos todo o suporte necessário para que você tenha uma
                experiência tranquila e focada no ensino. Nossa equipe técnica
                está sempre disponível para auxiliá-lo em todas as etapas, desde
                a configuração da plataforma até a publicação e otimização do
                seu curso, garantindo que tudo funcione perfeitamente.
                <br />
                <br />
                Além disso, ajudamos você a ampliar seu alcance e aumentar suas
                vendas com estratégias de marketing digital eficientes.
                Fornecemos orientações sobre posicionamento de mercado,
                precificação, criação de páginas atrativas e divulgação nas
                redes sociais, garantindo que seu curso alcance o público certo
                e gere resultados reais.
                <br />
                <br />
                <strong>
                  Na RozAcademy, você não apenas ensina, mas também constrói sua
                  autoridade e transforma seu conhecimento em uma fonte de renda
                  sustentável. 🚀
                </strong>
              </Paragraph>
            </Col>
          </Row>
        </TabItem>
      </TabList>
      <Modals.AddQuetionModal />
    </>
  );
});
export default TabTeach;
const Tabs = styled.nav``;
const Quetions = styled.nav`
  width: 100%;
`;
const Lesson = styled.p`
  padding: 10px 15px;
  background-color: #f0f0f0;
`;
const TabListUl = styled.ul``;
