import { inject, injectable } from "inversify";
import { makeAutoObservable, runInAction } from "mobx";
import { makePersistable } from "mobx-persist-store";

import SupabaseGateway from "../gateways/SupabaseGateway";
import { Tables } from "../types/enums";
import { useClassStore } from "../util/useClassStore";
import container from "./ioc";
import UIStore, { Drawers as Drawer, AlertType } from "./uiStore";
import { useNavigate } from "react-router-dom";

@injectable()
class PurchasedCourseStore {
  @inject(SupabaseGateway) private supabaseGateway!: SupabaseGateway;
  @inject(UIStore) ui!: UIStore;
  purchasedList!: IPurchasedCourse[];
  firstPurchasedList!: IPurchasedCourse[];
  purchasedSaved = false;
  constructor() {
    makeAutoObservable(this);
  }
  addPurchased = async (purchased: IPurchasedCourse) => {
    try {
      const { error, data } =
        await this.supabaseGateway.upSertToTable<IPurchasedCourse>(
          Tables.purchased_courses,
          purchased
        );
      await this.getPurchasedByUser(purchased.id_user as string);
      this.ui.showAlert(
        "Para concluir sua compra, acesse seu e-mail e finalize o pagamento.",
        AlertType.success
      );
      if (error) throw new Error(error.message);
      this.purchasedSaved = true;
      this.firstPurchasedList = data as IPurchasedCourse[];
      return data;
    } catch (error: any) {
      this.ui.showAlert(error.message, AlertType.error);
    }
  };
  getPurchasedOneByUser = async (course: string, user_id: string) => {
    try {
      const { data, error } = await this.supabaseGateway.getOneByColumns(
        Tables.purchased_courses,
        "id_user",
        "id_course",
        user_id as string,
        course as string
      );

      if (error) throw new Error(error.message);
      return { data, error };
    } catch (error) {}
  };
  getPurchasedByExternalId = async (id: string) => {
    try {
      const { data, error } =
        await this.supabaseGateway.selectFromTableWithFilter(
          Tables.purchased_courses,
          `*`,
          { column: "lasted_view_id", operator: "eq", value: id }
        );

      if (error) throw new Error(error.message);
      return data;
    } catch (error) {}
  };
  updatePurchased = async (purchased: IPurchasedCourse) => {
    try {
      const { error } =
        await this.supabaseGateway.updateTable<IPurchasedCourse>(
          Tables.purchased_courses,
          purchased,
          { id: purchased.id }
        );

      //await this.getModulesByCourseId(item_module?.course as string)
      /// this.ui.showAlert('Informações Guardadas', AlertType.success)
    } catch (error: any) {
      this.ui.showAlert(error.message, AlertType.error);
    }
  };

  getPurchasedByUser = async (id: string) => {
    try {
      const { data, error } =
        await this.supabaseGateway.selectFromTableWithFilter(
          Tables.purchased_courses,
          `*, cursos(*),video_viewed(*)`,
          { column: "id_user", operator: "eq", value: id }
        );
      runInAction(() => {
        this.purchasedList = data as IPurchasedCourse[];
      });
      if (error) throw new Error(error.message);
      return data;
    } catch (error: any) {
      this.ui.showAlert(error.message, AlertType.error);
    }
  };
  uploadComprovativoPayment = async (
    image: File,
    purchased: IPurchasedCourse
  ) => {
    try {
      const { publicUrl } =
        await this.supabaseGateway.uploadFile<IPurchasedCourse>(
          image,
          "ficheiros"
        );
      purchased.file = publicUrl;
      const { error, data } =
        await this.supabaseGateway.insertToTable<IPurchasedCourse>(
          Tables.purchased_courses,
          purchased
        );
      await this.getPurchasedByUser(purchased.id_user as string);
      this.ui.showAlert(
        "Comprovativo enviado com sucesso...",
        AlertType.success
      );
      this.ui.setDrawer(Drawer.PAYMENT_COURSE_DRAWER, false);
    } catch (error: any) {
      this.ui.showAlert(error.message, AlertType.error);
    }
  };
}
export const useUPurshasedCourseStore = () =>
  useClassStore<PurchasedCourseStore>(container.get(PurchasedCourseStore));

export default PurchasedCourseStore;
