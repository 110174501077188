import { observer } from "mobx-react-lite";
import FrontLayout from "../../../layouts/FrontLayout";
import {
  H1,
  Paragraph,
  ParagraphBold,
  Subtitle,
} from "../../../components/Typography";
import styled from "styled-components";
import { bg } from "../../../images";
import theme from "../../../theme";
import { device } from "../../../config/devices";
import loader from "../../../assets/teach.json";
import { Col, Rate, Row } from "rsuite";
import { useNavigate } from "react-router-dom";
import { Link, Element } from "react-scroll";
import Button from "../../../components/Buttons/Button";
import Lottie from "react-lottie";
import { ContentText, FormTeacher, Section } from "../style";
import TabTeach from "../../../components/TabSimple/TabTeach";
import { FaRegCheckSquare } from "react-icons/fa";
import TextInputLarge from "../../../components/Inputs/TextInputLarge";
import { FaLock, FaRegCircleCheck, FaUserLarge } from "react-icons/fa6";
import { MdMail } from "react-icons/md";
import { useReward } from "react-rewards";
import { useUserStore } from "../../../stores/userStore";
import { useUserPresenter } from "../../dashboard/user/presenter";
const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: loader,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};
const Teach: React.FC = observer(() => {
  const { user, isLoggedIn } = useUserStore();
  const { activateInstrutor, loadding } = useUserPresenter();
  const { reward, isAnimating } = useReward("rewardId", "confetti");
  const navigate = useNavigate();
  return (
    <>
      <FrontLayout>
        <SectionMain>
          <Content>
            <Row className="show-grid" style={{ padding: 0, margin: 0 }}>
              <Col
                lg={12}
                xl={13}
                xxl={13}
                md={24}
                sm={24}
                xs={24}
                style={{ padding: 0, margin: 0 }}
              >
                <ContainerMain>
                  <H1 color={theme.BACKGROUND.BLACK}>
                    Ensine, Inspire e Lucre.
                  </H1>
                  <Subtitle size="25px" color="#000" style={{ marginTop: 15 }}>
                    Você tem conhecimento valioso e paixão por ensinar? Então, a{" "}
                    Rozacademy é o lugar certo para você!
                  </Subtitle>
                  <Paragraph size={"16px"}>
                    Nossa plataforma conecta professores como você a milhares de
                    alunos sedentos por conhecimento. Aqui, você pode
                    compartilhar sua expertise, construir sua autoridade no
                    mercado e gerar uma renda extra – tudo no seu ritmo e sem
                    burocracia!
                  </Paragraph>
                  <Link to="section1" smooth={true} duration={500}>
                    <Button
                      label="COMEÇAR AGORA"
                      width="45"
                      color={theme.BACKGROUND.BLACK}
                    />
                  </Link>
                </ContainerMain>
              </Col>{" "}
              <Col
                lg={12}
                xl={11}
                xxl={11}
                md={24}
                sm={24}
                xs={24}
                style={{ padding: 0, margin: 0 }}
              >
                <ContentAnimation>
                  <Lottie options={defaultOptions} width="100%" />
                </ContentAnimation>
              </Col>
            </Row>
          </Content>
        </SectionMain>
        <Section style={{ backgroundColor: "#fff" }}>
          <ContentTab>
            <ParagraphBold size={"17px"} style={{ textAlign: "center" }}>
              O que oferecemos para você, professor?
            </ParagraphBold>
            <Paragraph size={"15px"} style={{ textAlign: "center" }}>
              Criar um curso online de sucesso exige mais do que apenas
              conhecimento. Por isso, na <strong>Rozacademy</strong>, oferecemos
              todo o
              <br />
              suporte necessário para que você foque no que realmente importa:
              ensinar e impactar vidas!
            </Paragraph>{" "}
            <TabTeach />
          </ContentTab>
        </Section>
        <Section>
          <Element name="section1">
            <Row>
              <Col lg={12} xl={12} xxl={12} md={24} sm={24} xs={24}>
                <ContentText>
                  <ParagraphBold size={"15px"}>
                    Compartilhe seu conhecimento e transforme vidas com a
                    Rozacademy!
                  </ParagraphBold>
                  <Paragraph size={"15px"}>
                    A Rozacademy oferece uma excelente oportunidade para
                    educadores que desejam expandir seus horizontes, alcançar
                    mais alunos e monetizar seus conhecimentos de forma
                    sustentável. Se você busca maior flexibilidade,
                    reconhecimento e crescimento financeiro, ensinar online na
                    Rozacademy é o caminho ideal para transformar sua expertise
                    em uma carreira de sucesso, diversificando sua economia e
                    garantindo múltiplas fontes de renda.
                  </Paragraph>
                  <ParagraphBold size={"15px"}>
                    Por que ensinar na Rozacademy?
                  </ParagraphBold>
                  <Paragraph size="15px">
                    <FaRegCheckSquare color={theme.BACKGROUND.BLUE} />{" "}
                    <strong>Alcance Global –</strong> Leve seu conhecimento para
                    alunos de diversas partes do mundo.
                  </Paragraph>
                  <Paragraph size="15px">
                    <FaRegCheckSquare color={theme.BACKGROUND.BLUE} />{" "}
                    <strong>Flexibilidade Total –</strong> Grave seus cursos no
                    seu ritmo e ensine de qualquer lugar.
                  </Paragraph>
                  <Paragraph size="15px">
                    <FaRegCheckSquare color={theme.BACKGROUND.BLUE} />{" "}
                    <strong>Monetização Justa –</strong> Transforme seu
                    conhecimento em uma fonte de renda sustentável.
                  </Paragraph>
                  <Paragraph size="15px">
                    <FaRegCheckSquare color={theme.BACKGROUND.BLUE} />{" "}
                    <strong>Suporte e Tecnologia –</strong> Conte com uma
                    plataforma intuitiva e suporte para criar cursos de alta
                    qualidade.
                  </Paragraph>
                  <ParagraphBold size={"15px"}>
                    🚀 Junte-se à Rozacademy e faça parte da revolução do ensino
                    online!
                  </ParagraphBold>
                </ContentText>
              </Col>
              <Col lg={12} xl={12} xxl={12} md={24} sm={24} xs={24}>
                <FormTeacher>
                  {!isLoggedIn && (
                    <>
                      <ParagraphBold size={"17px"}>
                        Inscreva-se hoje e dê o próximo passo na sua jornada
                        como educador!
                      </ParagraphBold>
                      <Paragraph size={"14px"}>
                        Se você ama compartilhar conhecimento e transformar
                        vidas por meio da educação, imagine o poder de levar
                        suas aulas para um número ilimitado de alunos, sem
                        barreiras geográficas ou restrições de horário. Com a
                        Rozacademy, essa possibilidade se torna real!
                      </Paragraph>
                      <TextInputLarge
                        placeholder="Escrever nome :"
                        hasLabel
                        label="Nome"
                        icon={
                          <FaUserLarge
                            size={20}
                            color="#999"
                            style={{ padding: 14 }}
                          />
                        }
                      />
                      <TextInputLarge
                        placeholder="Escrever email :"
                        hasLabel
                        label="Email"
                        icon={
                          <MdMail
                            size={22}
                            color="#999"
                            style={{ padding: 14 }}
                          />
                        }
                      />
                      <TextInputLarge
                        placeholder="Escrever palavra passe :"
                        hasLabel
                        label="Palavra Passe"
                        icon={
                          <FaLock
                            size={20}
                            color="#999"
                            style={{ padding: 14 }}
                          />
                        }
                      />
                      <TextInputLarge
                        placeholder="Confirmar Palavra Passe :"
                        hasLabel
                        label="Confirmar Palavra Passe"
                        icon={
                          <FaLock
                            size={20}
                            color="#999"
                            style={{ padding: 14 }}
                          />
                        }
                      />
                      <Button
                        label="CRIAR CONTA DE EDUCADOR"
                        color={theme.BACKGROUND.BLUE}
                      />
                    </>
                  )}
                  {isLoggedIn && user && (
                    <>
                      <CircleCardImage>
                        <CircleImage src={user?.img_url} />
                      </CircleCardImage>
                      <ParagraphBold
                        size={"16px"}
                        style={{ textAlign: "center" }}
                      >
                        {" "}
                        {user.name}
                      </ParagraphBold>
                      <Paragraph size={"14px"}>
                        Torne-se instrutor na Rozacademy e alcance milhares de
                        alunos com seus cursos online. Com uma plataforma
                        intuitiva, flexível e rentável, você pode ensinar do seu
                        jeito e transformar seu conhecimento em impacto e renda.
                      </Paragraph>
                      <span id="rewardId" />

                      {user && user.categoria == 1 ? (
                        <Button
                          label="CONTA DE INSTRUTOR ACTIVADA "
                          icon={<FaRegCircleCheck />}
                          color={theme.BACKGROUND.GREEN}
                        />
                      ) : (
                        <Button
                          label="ACTIVAR CONTA PARA INSTRUTOR"
                          loading={loadding}
                          onClick={async () => {
                            reward();
                            await activateInstrutor();
                          }}
                          color={theme.BACKGROUND.BLUE}
                        />
                      )}
                    </>
                  )}
                </FormTeacher>
              </Col>
            </Row>
          </Element>
        </Section>
      </FrontLayout>
    </>
  );
});
export default Teach;
const SectionMain = styled.section`
  width: 100%;
  min-height: 130px;
  background: url(${bg});
  background-position: 100% 50%;
  justify-content: center;
`;
const Content = styled.div`
  width: 100%;
  min-height: 130px;
  background-color: #dfe6f0;
`;
const CircleCardImage = styled.div`
  width: 200px;
  height: 200px;
  margin: 20px auto;
  border-radius: 50%;
  background-color: #dfe6f0;
`;
const CircleImage = styled.img`
  width: 200px;
  height: 200px;
  margin: auto;
  border-radius: 50%;
`;
const ContentTab = styled.div`
  width: 90%;
  margin: 20px auto;
`;
const ContainerMain = styled.div`
  margin: 5% 10px;
  padding: 40px;
  @media ${device.tablet} {
    margin: 5% 10px;
    padding: 20px;
  }

  @media ${device.laptop} {
    padding: 40px;
    margin: 5% 15px;
  }
`;
const ContentAnimation = styled.div`
  margin: 1% 10px;
  @media ${device.tablet} {
    padding: 0px;
    margin: 0% 10px;
  }

  @media ${device.laptop} {
    margin: 10% 15px;
  }
`;
