import { Col, Grid, Rate, Row } from "rsuite";
import styled from "styled-components";
import { Paragraph, ParagraphBold } from "../Typography";
import theme from "../../theme";
import { MdEdit } from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";
import { useCoursePresenter } from "../../pages/dashboard/instrutor/cursos/presenter";
import { cutText } from "../../util/stringUtils";

interface IProps {
  data: Array<ICourse>;
}
const GridCourse = ({ data }: IProps) => {
  const navigate = useNavigate();
  const { sliceString, resetStoreValues } = useCoursePresenter();
  return (
    <Grid fluid>
      <Row gutter={16}>
        {data.map((item, i) => (
          <Col
            xxl={4}
            xl={4}
            lg={6}
            md={8}
            xs={12}
            sm={12}
            style={{ marginBottom: 10 }}
          >
            <Link
              to={`${item?.external_id}/manage/basic`}
              style={{ textDecoration: "none" }}
              title={item?.titulo}
            >
              <div className="show-col">
                <ImageCourse src={item?.img_url} />
                <InfoCourse>
                  <ParagraphBold style={{ marginLeft: 10, marginRight: 10 }}>
                    {cutText(item?.titulo as string, 70)}
                  </ParagraphBold>
                  <div>
                    <Paragraph
                      size="11px"
                      style={{ marginLeft: 10, marginRight: 10 }}
                    >
                      <Rate
                        defaultValue={5}
                        color="yellow"
                        size="xs"
                        readOnly
                      />
                    </Paragraph>
                    <ParagraphBold
                      color={theme.BACKGROUND.GREEN}
                      style={{ marginLeft: 10, marginRight: 10 }}
                    >
                      {item?.price ? "" : "Grátis"}
                    </ParagraphBold>
                  </div>
                </InfoCourse>
              </div>
            </Link>
          </Col>
        ))}
      </Row>
    </Grid>
  );
};
export default GridCourse;
const ImageCourse = styled.img`
  width: 100%;
`;
const InfoCourse = styled.div`
  width: 100%;
  padding: 10px 0;
  background-color: #fff;
`;
const MyButton = styled.button`
  margin-right: 5px;
  padding: 6px;
  margin-top: 10px;
  font-family: Montserrat;
  font-size: 11px;
`;
