import { observer } from "mobx-react-lite";
import styled from "styled-components";
import theme from "../../../theme";
import { device } from "../../../config/devices";
import { ImageCardCourse, Section } from "../style";
import { Col, FlexboxGrid, Rate, Row, Tabs } from "rsuite";
import { useCoursePresenter } from "../../dashboard/instrutor/cursos/presenter";
import { Link, useNavigate, useParams } from "react-router-dom";
import React from "react";
import {
  Paragraph,
  ParagraphBold,
  Strong,
} from "../../../components/Typography";
import { format } from "date-fns";
import { pt } from "date-fns/locale";
import {
  FaChartSimple,
  FaFilm,
  FaInfinity,
  FaRegClock,
  FaRegUser,
  FaShareFromSquare,
  FaVolumeLow,
} from "react-icons/fa6";
import { MdAccessTime, MdOutlineOndemandVideo } from "react-icons/md";
import Button from "../../../components/Buttons/Button";
import { FaMobileAlt } from "react-icons/fa";
import { useLayoutEffect, useRef } from "react";
import { useAuthPresenter } from "../presenter";
import ButtonBorder from "../../../components/Buttons/ButtonBorder";
import Loadding from "../../../components/loadding/page";
import { Title } from "../training-center";
import { CardMoreInfo, TabCourseDetail } from "../../dashboard/style";
import TabCourse from "../../../components/TabSimple/TabCourse";
import { useUserStore } from "../../../stores/userStore";
const CourseDetail: React.FC = observer(() => {
  const {
    loadding,
    getFormatCurrent,
    getCourseId,
    course,
    calculateFifteenPercent,
    moduleList,
    checkPurchasedCourse,
    getQtdAula,
    getTotalTimeCourse,
  } = useCoursePresenter();
  const { id_course } = useParams();
  const { isLoggedIn } = useUserStore();
  const navigate = useNavigate();
  const div = document.getElementById("infocourse");

  const onScroll = () => {
    if (div) {
      const scrolledTo = window.scrollY + window.innerHeight;
      if (window.innerWidth > 1080) {
        if (window.scrollY >= 380) {
          div.style.position = "absolute";
          div.style.marginTop = `${window.scrollY * 0.55 - 240}px`;
        } else {
          div.style.position = "relative";
          div.style.marginTop = `${window.scrollY}px`;
        }
      } else {
        div.style.position = "relative";
        div.style.marginTop = "0";
      }
    }
  };
  window.addEventListener("scroll", onScroll);

  React.useEffect(() => {
    getCourseId(id_course as string);
  }, []);
  return (
    <>
      <Section style={{ paddingTop: 0, marginTop: 0 }}>
        {loadding && <Loadding />}
        {course && (
          <>
            <HeaderCourse>
              <Row>
                <Col lg={14} xl={16} xxl={16} md={24} sm={24} xs={24}>
                  <ContentCourse>
                    <Title color="#fff" style={{ marginBottom: 10 }}>
                      {course?.titulo}
                    </Title>
                    {course.subtitulo && (
                      <Paragraph color="#fff" size={"17px"}>
                        {course.subtitulo}
                      </Paragraph>
                    )}
                    <FlexboxGrid justify="space-around">
                      <FlexboxGrid.Item
                        as={Col}
                        colspan={8}
                        xxl={8}
                        xl={8}
                        lg={8}
                        md={12}
                        sm={24}
                        xs={24}
                      >
                        <Paragraph size="14px" color="#b6bbcf">
                          <FaInfinity style={{ marginRight: 10 }} /> Acesso
                          total vitalício.
                        </Paragraph>
                      </FlexboxGrid.Item>

                      <FlexboxGrid.Item
                        as={Col}
                        colspan={10}
                        xxl={10}
                        xl={10}
                        lg={10}
                        md={12}
                        sm={24}
                        xs={24}
                      >
                        <Paragraph size="14px" color="#b6bbcf">
                          <FaMobileAlt style={{ marginRight: 10 }} /> Acesso no
                          dispositivo móvel
                        </Paragraph>
                      </FlexboxGrid.Item>
                      <FlexboxGrid.Item
                        as={Col}
                        colspan={6}
                        xxl={6}
                        xl={6}
                        lg={6}
                        md={12}
                        sm={24}
                        xs={24}
                      >
                        <Paragraph size="14px" color="#b6bbcf">
                          <FaVolumeLow style={{ marginRight: 10 }} /> Áudio:
                          Português
                        </Paragraph>
                      </FlexboxGrid.Item>
                    </FlexboxGrid>
                    <ParagraphBold size={"14px"} color="#e9e9e9">
                      Criado por{"  "}
                      <Link to={""}>
                        <strong>{course.users?.name}</strong>
                      </Link>
                      , {course.users?.profissao} .
                    </ParagraphBold>
                    {isLoggedIn && !checkPurchasedCourse() && (
                      <>
                        {isLoggedIn && (
                          <Button
                            label="COMPRAR CURSO"
                            width="50"
                            onClick={() => {
                              navigate("/cursos/" + id_course + "/checkout");
                            }}
                            color={theme.BACKGROUND.GREEN}
                          />
                        )}
                      </>
                    )}
                    {isLoggedIn && checkPurchasedCourse() && (
                      <>
                        <Button
                          label="CURSO JÁ ADQUIRIDO "
                          width="50"
                          onClick={() => {
                            navigate("/dashboard/user/meu-percurso");
                          }}
                          color={theme.BACKGROUND.BLUE_OCEAN}
                        />
                      </>
                    )}

                    {!isLoggedIn && (
                      <Button
                        label="COMPRAR CURSO"
                        width="50"
                        onClick={() => {
                          navigate("/signup");
                        }}
                        color={theme.BACKGROUND.GREEN}
                      />
                    )}

                    <ButtonBorder
                      label="COMPARTILHAR"
                      width="40"
                      color={theme.BACKGROUND.BLUE_OCEAN}
                    />
                  </ContentCourse>
                </Col>
                <Col lg={10} xl={8} xxl={8} md={24} sm={24} xs={24}>
                  <ImageCardCourse src={course?.img_url} />
                  <CardMoreInfo>
                    <Paragraph size={"14px"} style={{ margin: "0 20px" }}>
                      Preço Total
                    </Paragraph>
                    <Paragraph style={{ textAlign: "left", margin: "0 20px" }}>
                      {course?.price ? (
                        <Strong
                          size={"24px "}
                          color={theme.BACKGROUND.GREEN}
                        >{`${getFormatCurrent(
                          course?.price as number
                        )} Kz`}</Strong>
                      ) : (
                        "Grátis"
                      )}{" "}
                      desconto de 15%{" "}
                      <Strong size={"15px"} color={theme.BACKGROUND.BLACK}>
                        <s>
                          {" "}
                          {getFormatCurrent(
                            calculateFifteenPercent(
                              course?.price as number
                            ) as number
                          )}{" "}
                          kz
                        </s>
                      </Strong>
                    </Paragraph>
                    <Paragraph style={{ textAlign: "left", margin: "0 20px" }}>
                      <Rate readOnly defaultValue={5} color="yellow" />
                    </Paragraph>
                  </CardMoreInfo>
                </Col>
              </Row>
            </HeaderCourse>
            <Content>
              <Row className="show-grid">
                <Col lg={14} xl={16} xxl={16} md={24} sm={24} xs={24}>
                  <GridAboutCourse>
                    {course.link_promocional && (
                      <div
                        style={{
                          position: "relative",
                          paddingTop: "56.25%",
                          marginBottom: 10,
                        }}
                      >
                        <iframe
                          id={`panda-${course.link_promocional}`}
                          src={course.link_promocional}
                          style={{
                            border: "none",
                            position: "absolute",
                            top: 0,
                            left: 0,
                          }}
                          allow="accelerometer;gyroscope;autoplay;encrypted-media;picture-in-picture"
                          allowFullScreen={true}
                          width="100%"
                          height="100%"
                        />
                      </div>
                    )}
                    <TabCourseDetail>
                      <Content>
                        <TabCourse />
                      </Content>
                    </TabCourseDetail>
                  </GridAboutCourse>
                </Col>
                <Col lg={10} xl={8} xxl={8} md={24} sm={24} xsHidden mdHidden>
                  <GridDetailCourse id="infocourse">
                    <DetailWrapper>
                      <ParagraphBold size="15px" style={{ margin: 0 }}>
                        <FaChartSimple /> Nível Iniciante
                      </ParagraphBold>
                      <Paragraph style={{ margin: 0 }}>
                        Experiência recomendada
                      </Paragraph>
                      <hr />
                      <ParagraphBold>Este curso inclui :</ParagraphBold>
                      {moduleList && (
                        <Paragraph size="14px">
                          <MdOutlineOndemandVideo
                            color="#999"
                            style={{ marginRight: 10 }}
                          />{" "}
                          {moduleList.length} Módulos
                        </Paragraph>
                      )}
                      {moduleList && (
                        <Paragraph size="14px">
                          <FaFilm color="#999" style={{ marginRight: 10 }} />{" "}
                          {getQtdAula(moduleList)} Aulas
                        </Paragraph>
                      )}
                      <Paragraph size="14px">
                        <FaRegClock color="#999" style={{ marginRight: 10 }} />{" "}
                        {getTotalTimeCourse()} de vídeo sob demanda
                      </Paragraph>
                      <Paragraph size="14px">
                        <FaMobileAlt color="#999" style={{ marginRight: 10 }} />{" "}
                        Acesso no dispositivo móvel e na TV
                      </Paragraph>
                      <Paragraph size="14px">
                        <FaInfinity color="#999" style={{ marginRight: 10 }} />{" "}
                        Acesso total vitalício.
                      </Paragraph>
                      <Paragraph size="14px">
                        <FaVolumeLow color="#999" style={{ marginRight: 10 }} />{" "}
                        Áudio: Português
                      </Paragraph>
                      <Paragraph size="14px">
                        <FaRegUser color="#999" style={{ marginRight: 10 }} /> 6
                        estudantes
                      </Paragraph>
                    </DetailWrapper>
                  </GridDetailCourse>
                </Col>
              </Row>
            </Content>
          </>
        )}
      </Section>
    </>
  );
});
export default CourseDetail;
const ContentCourse = styled.div`
  padding: 50px 65px;
  @media ${device.laptopL} {
  }
  @media ${device.tablet} {
    padding: 20px 20px;
  }
`;
const HeaderCourse = styled.div`
  width: 100%;
  margin-bottom: 20px;
  min-height: 250px;
  background-color: ${theme.BACKGROUND.THEME};
`;
const DetailWrapper = styled.div`
  padding: 20px 20px;
`;

const Content = styled.div`
  width: 90%;
  margin: auto;
  @media ${device.laptopL} {
    width: 88%;
  }
  @media ${device.mobileL} {
    width: 98%;
  }
`;
const GridAboutCourse = styled.div`
  width: 100%;
  margin-top: 0px;
  margin-bottom: 20px;
  h1 {
    font-family: Montserrat;
    @media ${device.laptopL} {
      font-size: 60px;
      margin-bottom: 18px;
    }
    @media ${device.mobileL} {
      font-size: 25px;
      line-height: 19px;
      margin-bottom: 10px;
    }
  }
`;
const GridDetailCourse = styled.div`
  width: 100%;
  margin-bottom: 10px;
  margin-top: 10px;
  background-color: #fff;
  padding: 10px 0;
  border: 1px solid #e0e0e0;
  border-radius: 0;
  @media ${device.mobileL} {
  }
`;
