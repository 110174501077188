import { observer } from "mobx-react-lite";
import FrontLayout from "../../../layouts/FrontLayout";
import { H5, Paragraph, ParagraphBold } from "../../../components/Typography";
import styled from "styled-components";
import { bg8, bg9 } from "../../../images";
import theme from "../../../theme";
import { device } from "../../../config/devices";
import { FormWrapper, Section } from "../style";
import { useCoursePresenter } from "../../dashboard/instrutor/cursos/presenter";
import { Col, Grid, Row } from "rsuite";
import { FaCheckSquare, FaMapMarkerAlt } from "react-icons/fa";
import TextInputLarge from "../../../components/Inputs/TextInputLarge";
import { MdMail, MdSchool } from "react-icons/md";
import Button from "../../../components/Buttons/Button";
import { FaFile, FaPhone, FaUserLarge } from "react-icons/fa6";
const TrainingCenter: React.FC = observer(() => {
  return (
    <>
      <FrontLayout>
        <Section style={{ margin: 0, padding: 0 }}>
          <Row>
            <Col lg={12} xl={12} xxl={12} md={24} sm={24} xs={23}>
              <ContentText>
                <ImageMain src={bg9} />
                <Title color="#333" style={{ textAlign: "left" }}>
                  Inove no ensino: Digitalize, inspire e transforme com a
                  Rozacademy !
                </Title>
                <Paragraph size="17px" style={{ marginTop: 15 }}>
                  Transforme a experiência de aprendizado! <br />
                  Digitalize seus cursos e descubra como os centros de formação
                  mais inovadores estão revolucionando o ensino com a nossa
                  plataforma.
                  <br />
                  Flexibilidade, tecnologia e excelência para um aprendizado sem
                  limites!
                </Paragraph>
                <Paragraph size="16px">
                  <FaCheckSquare color={theme.BACKGROUND.BLUE} />{" "}
                  <strong>Alcance ilimitado – </strong>Ensine para alunos em
                  qualquer lugar, sem barreiras geográficas.
                </Paragraph>
                <Paragraph size="16px">
                  <FaCheckSquare color={theme.BACKGROUND.BLUE} />{" "}
                  <strong>Redução de custos –</strong> Menos gastos com
                  infraestrutura física, materiais impressos e deslocamento de
                  professores.
                </Paragraph>
                <Paragraph size="16px">
                  <FaCheckSquare color={theme.BACKGROUND.BLUE} />{" "}
                  <strong>Escalabilidade – </strong> Um único curso pode ser
                  acessado por centenas ou milhares de alunos sem aumento
                  significativo de custos.
                </Paragraph>
                <Paragraph size="16px">
                  <FaCheckSquare color={theme.BACKGROUND.BLUE} />{" "}
                  <strong>Gestão Inteligente – </strong>
                  Plataformas EAD oferecem relatórios detalhados sobre o
                  desempenho dos alunos, facilitando a tomada de decisões.
                </Paragraph>
                <Paragraph size="16px">
                  <FaCheckSquare color={theme.BACKGROUND.BLUE} />{" "}
                  <strong>Maior Competitividade –</strong>
                  Centros de formação que oferecem ensino a distância se
                  destacam no mercado e atraem mais alunos.
                </Paragraph>
                <Paragraph size={"15px"}>
                  Implementar a <strong>Rozacademy</strong> não é apenas uma
                  tendência, mas uma necessidade para acompanhar a transformação
                  digital na educação. Seu centro de formação se tornará mais
                  eficiente, inclusivo e preparado para o futuro. 🚀
                </Paragraph>
              </ContentText>
            </Col>
            <Col
              lg={12}
              xl={12}
              xxl={12}
              md={24}
              sm={24}
              xs={24}
              style={{ textAlign: "left" }}
            >
              <FormWrapper>
                <ParagraphBold size={"20px"}>
                  Transforme seu centro de formação com a educação digital .
                </ParagraphBold>
                <Paragraph size="15px" style={{ textAlign: "left" }}>
                  Leve seus cursos para o mundo digital e ofereça uma
                  experiência de aprendizado inovadora com a Rozacademy. Aumente
                  seu alcance, reduza custos e proporcione um ensino mais
                  dinâmico, acessível e eficiente.
                </Paragraph>

                <Row>
                  <Col lg={24} xl={24} xxl={24} md={24} sm={24} xs={24}>
                    <ParagraphBold size={"15px"}>
                      Solicite a adesão do seu centro de formação e revolucione
                      a educação com inovação digital.
                    </ParagraphBold>
                    <TextInputLarge
                      placeholder="Escrever nome completo :"
                      hasLabel
                      label="Nome completo"
                      icon={
                        <FaUserLarge
                          color="#999"
                          size={26}
                          style={{ padding: "10px 14px" }}
                        />
                      }
                    />
                  </Col>
                  <Col lg={24} xl={24} xxl={24} md={24} sm={24} xs={24}>
                    <TextInputLarge
                      placeholder="Escrever Email :"
                      hasLabel
                      label="Email"
                      icon={
                        <MdMail
                          color="#999"
                          size={29}
                          style={{ padding: "10px 14px" }}
                        />
                      }
                    />
                  </Col>
                  <Col lg={24} xl={24} xxl={24} md={24} sm={24} xs={24}>
                    <TextInputLarge
                      placeholder="Escrever nº de telefone:"
                      hasLabel
                      label="Nº de  telefone"
                      icon={
                        <FaPhone
                          color="#999"
                          size={23}
                          style={{ padding: "10px 10px" }}
                        />
                      }
                    />
                  </Col>
                  <Col
                    style={{ marginTop: 25 }}
                    lg={24}
                    xl={24}
                    xxl={24}
                    md={24}
                    sm={24}
                    xs={24}
                  >
                    <TextInputLarge
                      placeholder="Escrever nome :"
                      hasLabel
                      label="Nome do centro de formação "
                      icon={
                        <MdSchool
                          color="#999"
                          size={26}
                          style={{ padding: "10px 14px" }}
                        />
                      }
                    />
                  </Col>
                  <Col lg={24} xl={24} xxl={24} md={24} sm={24} xs={24}>
                    <TextInputLarge
                      placeholder="Escrever NIF:"
                      hasLabel
                      icon={
                        <FaFile
                          color="#999"
                          size={23}
                          style={{ padding: "10px 10px" }}
                        />
                      }
                      label="NIF"
                    />
                  </Col>
                  <Col lg={24} xl={24} xxl={24} md={24} sm={24} xs={24}>
                    <TextInputLarge
                      placeholder="Escrever endereço:"
                      hasLabel
                      icon={
                        <FaMapMarkerAlt
                          color="#999"
                          size={23}
                          style={{ padding: "10px 10px" }}
                        />
                      }
                      label="Endereço"
                    />
                  </Col>
                  <Col lg={24} xl={24} xxl={24} md={24} sm={24} xs={24}>
                    <Button
                      color={theme.BACKGROUND.BLUE}
                      label="ENVIAR PEDIDO"
                    />
                  </Col>
                </Row>
              </FormWrapper>
            </Col>
          </Row>
        </Section>
      </FrontLayout>
    </>
  );
});
export default TrainingCenter;
interface IProps {
  backgroundImage?: string;
}
export const ImageMain = styled.img<IProps>`
  width: 100%;
  @media ${device.tablet} {
  }
`;
export const PlayVideo = styled.div<IProps>`
  width: 100%;
  min-height: 350px;
  background-color: #f4f4f4;
  @media ${device.mobileL} {
    min-height: 200px;
  }
`;
export const PlayVideoButton = styled.div<IProps>`
  width: 60px;
  height: 60px;
  background-color: ${theme.BACKGROUND.BLUE};
  margin: auto;
  margin-top: 40px;
  border-radius: 50%;
`;
const ContentText = styled.div`
  padding: 25px 20px;
  text-align: left;
  @media ${device.tablet} {
    padding: 15px;
  }

  @media ${device.laptop} {
  }
`;
export const TextMargin = styled.div`
  @media ${device.mobileL} {
  }
`;

export const Image = styled.img<IProps>`
  width: 100%;
`;
export const Title = styled.h5<IProps>`
  font-family: Montserrat;
  margin-top: 10px;
  color: ${({ color }) => (color ? color : "#000")};
  font-size: 40px;
  line-height: 100%;
  @media ${device.mobileL} {
    font-size: 18px;
  }
`;
