import { observer } from "mobx-react-lite";
import styled from "styled-components";
import { logo } from "../../images";
import THEME from "../../theme/";
import { Link, useNavigate } from "react-router-dom";
import { FaSearch } from "react-icons/fa";
import { Paragraph, ParagraphBold } from "../Typography";
import TextInputSearchMain from "../Inputs/TextInputSearchMain";
import { Drawers as Drawer, useUiStore } from "../../stores/uiStore";
import { Drawers } from "../../components";
import { useUserStore } from "../../stores/userStore";
import { Avatar, Col, Dropdown, IconButton, Row } from "rsuite";
import { device } from "../../config/devices";
import Button from "../Buttons/Button";
import {
  FaBars,
  FaBriefcase,
  FaChartPie,
  FaDesktop,
  FaRocket,
  FaUser,
  FaUserGraduate,
  FaUserLarge,
  FaUserPlus,
  FaUserTie,
} from "react-icons/fa6";
import theme from "../../theme/";
import Item from "antd/es/list/Item";
interface ICategorias {
  label?: string;
  icon?: JSX.Element;
  value?: number;
  descript?: string;
  color?: string;
  path?: string;
}

const categorias: ICategorias[] = [
  {
    label: "Desenvolvimento & programação",
    value: 1,
    icon: <FaDesktop color="#e61336" size={24} style={{ marginTop: 5 }} />,
    descript: "",
    color: "#e61336",
    path: "desenvolvimeno-programacao",
  },
  {
    label: "Acadêmico",
    value: 2,
    icon: <FaUserGraduate size={25} style={{ marginTop: 5 }} color="#f5d611" />,
    descript: "",
    color: "#14ba7d",
    path: "academico",
  },
  {
    label: "Administração Pública",
    value: 2,
    icon: <FaBriefcase size={24} style={{ marginTop: 5 }} color="#09e3b0" />,
    descript: "",
    color: "#14ba7d",
    path: "administracao-publica",
  },
  {
    label: "Finanças & contabilidade",
    value: 2,
    icon: <FaChartPie color="#e36f09" size={24} style={{ marginTop: 5 }} />,
    descript: "",
    color: "#14ba7d",
    path: "financa-contabilidade",
  },
  {
    label: "Produtividade no escritório",
    value: 2,
    icon: <FaRocket color="#55f511" size={24} style={{ marginTop: 5 }} />,
    descript: "",
    color: "#14ba7d",
    path: "produtividade-no-escritorio",
  },
  {
    label: "Desenvolvimento pessoal",
    value: 2,
    icon: <FaUserTie color="#11b1f5" size={24} style={{ marginTop: 5 }} />,
    descript: "",
    color: "#14ba7d",
    path: "desenvolvimento-pessoal",
  },
];
const HeaderFront: React.FC = observer(() => {
  const { setDrawer } = useUiStore();
  const { user, isLoggedIn } = useUserStore();
  const navigate = useNavigate();
  const renderToggle = (props: any) =>
    user && (
      <Avatar
        style={{ marginTop: 10 }}
        circle
        {...props}
        src={user.img_url ? user.img_url : ""}
      />
    );
  const renderIconButton = (props: any, ref: any) => {
    return (
      <IconButton
        {...props}
        ref={ref}
        icon={<FaBars color="#000" size={30} />}
        color="white"
        appearance="white"
      />
    );
  };
  return (
    <>
      <TopHeader>
        <Menu>
          <Row className="show-grid" style={{ padding: 0, margin: 0 }}>
            <Col
              lg={6}
              xl={5}
              xxl={6}
              md={12}
              sm={24}
              xs={24}
              style={{ padding: 0, margin: 0 }}
            >
              <Link to="/training-center">
                <Paragraph color="#fff">PARA CENTROS DE FORMAÇÃO</Paragraph>
              </Link>
            </Col>
            <Col
              lg={6}
              xl={4}
              xxl={6}
              md={12}
              sm={24}
              xs={24}
              style={{ padding: 0, margin: 0 }}
            >
              <Link to="/university">
                <Paragraph color="#fff">PARA UNIVERSIDADES</Paragraph>
              </Link>
            </Col>
            <Col
              lg={6}
              xl={3}
              xxl={6}
              md={12}
              sm={24}
              xs={24}
              style={{ padding: 0, margin: 0 }}
            >
              <Link to="/company">
                <Paragraph color="#fff">PARA EMPRESAS</Paragraph>
              </Link>
            </Col>
          </Row>
        </Menu>
      </TopHeader>
      <Container>
        <Header>
          <Row className="show-grid">
            <Col
              lg={4}
              xl={4}
              xxl={4}
              md={3}
              sm={4}
              xs={4}
              lgHidden
              xlHidden
              xxlHidden
            >
              <Dropdown renderToggle={renderIconButton}>
                <Dropdown.Menu title="Explorar">
                  {categorias.map((item, i) => (
                    <Dropdown.Item
                      onClick={() => navigate(`/categoria/${item?.path}`)}
                    >
                      <Paragraph>{item?.label}</Paragraph>
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>

                {!isLoggedIn ? (
                  <>
                    <Dropdown.Item
                      icon={<FaUserLarge />}
                      onClick={() => navigate(`/login`)}
                    >
                      Entrar
                    </Dropdown.Item>
                    <Dropdown.Item
                      icon={<FaUserPlus />}
                      onClick={() => navigate(`/signup`)}
                    >
                      Criar conta
                    </Dropdown.Item>
                  </>
                ) : (
                  <>
                    <Dropdown.Separator />
                    <Dropdown.Item
                      onClick={() => {
                        navigate("/dashboard/user/meu-percurso");
                      }}
                    >
                      <ParagraphBold>Meu percurso</ParagraphBold>
                    </Dropdown.Item>
                    {(user && user.categoria == 1) ||
                    (user && user.categoria == 3) ? (
                      <Dropdown.Item
                        onClick={() => {
                          navigate("/dashboard/instrutor");
                        }}
                      >
                        <ParagraphBold>Painel de instrutor</ParagraphBold>
                      </Dropdown.Item>
                    ) : (
                      ""
                    )}
                    <Dropdown.Separator />
                    <Dropdown.Item
                      onClick={() => {
                        navigate("/dashboard/user/config");
                      }}
                    >
                      <ParagraphBold>Configurações de conta</ParagraphBold>{" "}
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => {
                        navigate("/dashboard/user/editar-profile");
                      }}
                    >
                      <ParagraphBold>Editar perfil</ParagraphBold>{" "}
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <ParagraphBold>Sair</ParagraphBold>
                    </Dropdown.Item>
                  </>
                )}
              </Dropdown>
            </Col>
            <Col lg={3} xl={3} xxl={3} md={5} xs={10} sm={10}>
              <AppLogo>
                <Link to={"/"}>
                  <Logo src={logo} />
                </Link>
              </AppLogo>
            </Col>
            <Col
              lg={7}
              xl={7}
              xxl={8}
              xlOffset={1}
              xxlOffset={1}
              smHidden
              mdHidden
              xsHidden
            >
              <TextInputSearchMain
                placeholder="O que você deseja aprender ?"
                width={100}
                icon={
                  <FaSearch size={14} style={{ padding: 8 }} color="#999" />
                }
              />
            </Col>
            <Col
              lg={3}
              xl={3}
              xxl={2}
              md={3}
              xs={24}
              smHidden
              xsHidden
              mdHidden
              style={{ marginTop: 8 }}
            >
              <Dropdown title="Explorar" style={{ marginLeft: "3%" }}>
                {categorias.map((item, i) => (
                  <Dropdown.Item
                    onClick={() => navigate(`/categoria/${item?.path}`)}
                  >
                    <Paragraph>{item?.label}</Paragraph>
                  </Dropdown.Item>
                ))}
              </Dropdown>
            </Col>
            {isLoggedIn ? (
              <>
                <Col
                  lg={8}
                  xl={8}
                  xxl={7}
                  xs={4}
                  mdHidden
                  smHidden
                  xsHidden
                  style={{ marginTop: 13 }}
                >
                  <ParagraphBold>
                    <Link
                      to="/teach"
                      style={{ marginRight: 13, color: "#000" }}
                    >
                      Ensine na Rozacademy
                    </Link>
                    <Link
                      to="/dashboard/user/meu-percurso"
                      style={{ color: "#000" }}
                    >
                      Meu Percurso
                    </Link>
                  </ParagraphBold>
                </Col>
                <Col
                  lg={2}
                  xl={2}
                  xxl={2}
                  md={9}
                  xs={4}
                  smHidden
                  mdHidden
                  xsHidden
                >
                  <Dropdown renderToggle={renderToggle} placement="bottomEnd">
                    <Dropdown.Item panel style={{ padding: 10, width: 220 }}>
                      <Paragraph style={{ padding: 0, margin: 0 }}>
                        Logado como :
                      </Paragraph>
                      <ParagraphBold style={{ padding: 0, margin: 0 }}>
                        {user?.name}
                      </ParagraphBold>
                    </Dropdown.Item>
                    <Dropdown.Separator />
                    <Dropdown.Item
                      onClick={() => {
                        navigate("/dashboard/user/meu-percurso");
                      }}
                    >
                      <ParagraphBold>Meu percurso</ParagraphBold>
                    </Dropdown.Item>
                    {(user && user.categoria == 1) ||
                    (user && user.categoria == 3) ? (
                      <Dropdown.Item
                        onClick={() => {
                          navigate("/dashboard/instrutor");
                        }}
                      >
                        <ParagraphBold>Painel de instrutor</ParagraphBold>
                      </Dropdown.Item>
                    ) : (
                      ""
                    )}
                    <Dropdown.Separator />
                    <Dropdown.Item
                      onClick={() => {
                        navigate("/dashboard/user/config");
                      }}
                    >
                      <ParagraphBold>Configurações de conta</ParagraphBold>{" "}
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => {
                        navigate("/dashboard/user/editar-profile");
                      }}
                    >
                      <ParagraphBold>Editar perfil</ParagraphBold>{" "}
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <ParagraphBold>Sair</ParagraphBold>
                    </Dropdown.Item>
                  </Dropdown>
                </Col>
              </>
            ) : (
              <>
                <Col lg={8} xl={9} xxl={7} md={14} xs={24} smHidden xsHidden>
                  <ButtonLogin onClick={() => navigate(`/login`)}>
                    <FaUser /> ENTRAR
                  </ButtonLogin>
                  <ButtonSignUp onClick={() => navigate(`/signup`)}>
                    <FaUserPlus size={14} /> CRIAR CONTA
                  </ButtonSignUp>
                </Col>
              </>
            )}
          </Row>
        </Header>
      </Container>
      <Drawers.MenuMobile />
    </>
  );
});

const Container = styled.div`
  width: 100%;
  min-height: 50px;
  background-color: #fff;
  border-bottom: 1px solid #e0e0e0;
  @media ${device.mobileL} {
  }
`;
const TopHeader = styled.div`
  width: 100%;
  min-height: 10px;
  padding: 10px 0;
  background-color: ${THEME.BACKGROUND.BLACK};
  display: flex;
  flex-direction: row;
  justify-content: center;
  @media ${device.mobileL} {
  }
`;
const Menu = styled.div`
  width: 90%;
  padding: 10x 0;
  a {
    color: #fff;
  }
`;
const ButtonSignUp = styled.button`
  width: 44%;
  border-radius: 30px;
  height: 35px;
  color: #000;
  margin-top: 7px;
  margin-left: 2%;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: bold;
  background-color: #f0f0f0;
  outline: none;
`;
const ButtonLogin = styled.button`
  width: 40%;
  border-radius: 30px;
  height: 35px;
  color: #fff;
  margin-top: 7px;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: bold;
  margin-left: 12%;
  outline: none;
  background-color: ${theme.BACKGROUND.BLUE};
`;
const Header = styled.div`
  width: 90%;
  margin: 0 auto;
`;
const AppLogo = styled.div`
  width: 100%;
  margin-top: 9px;
  @media ${device.tablet} {
    margin-top: 4px;
  }
`;
const Logo = styled.img`
  width: 100%;
  margin-top: 6px;
  @media ${device.tablet} {
    margin-top: 10px;
  }
`;
const ButtonGroup = styled.div`
  width: 40%;
  list-style: none;
  display: flex;
  flex-direction: row;
  margin-top: 7px;
  justify-content: flex-end;
  a {
    padding: 0 0;
    color: #000;
    text-decoration: none;
  }
`;
const LinkLogin = styled.div`
  margin-top: 10px;
  padding: 8px 15px;
  border: 1px solid ${THEME.BACKGROUND.BLACK};
`;

export default HeaderFront;
