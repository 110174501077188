import { observer } from "mobx-react-lite";
import FrontLayout from "../../../layouts/FrontLayout";
import styled from "styled-components";
import { device } from "../../../config/devices";
import { ContentForm, FormLogin, Section } from "../style";
import { Link, useNavigate, useParams } from "react-router-dom";
import React, { useEffect } from "react";
import { Paragraph, ParagraphBold } from "../../../components/Typography";
import { Col, Row } from "rsuite";
import { FaLock, FaUser } from "react-icons/fa6";
import theme from "../../../theme";
import TextInput from "../../../components/Inputs/TextInput";
import Button from "../../../components/Buttons/Button";
import { idea } from "../../../images";
import { useReward } from "react-rewards";
import { useAuthPresenter } from "../presenter";
import { useUserStore } from "../../../stores/userStore";

const LoginScreen: React.FC = observer(() => {
  const navigate = useNavigate();
  const { setFormValue, login, loading } = useAuthPresenter();
  const { isLoggedIn, user } = useUserStore();
  const { reward, isAnimating } = useReward("rewardId", "confetti");
  useEffect(() => {
    if (isLoggedIn) navigate(`/dashboard/user/meu-percurso`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn]);
  return (
    <>
      <FrontLayout>
        <Section>
          <Content>
            <AuthForm>
              <Row>
                <Col
                  xl={12}
                  xxl={12}
                  lg={9}
                  md={7}
                  sm={24}
                  smHidden
                  xsHidden
                  xs={24}
                >
                  <ContentImage>
                    <ImageLogin src={idea} />
                  </ContentImage>
                </Col>
                <Col xl={12} xxl={12} lg={15} md={17} sm={24} xs={24}>
                  <FormLogin>
                    <ContentForm>
                      <ParagraphBold size="17px" style={{ marginTop: 10 }}>
                        🚀 Volte para a Rozacademy e continue sua jornada de
                        sucesso!
                      </ParagraphBold>
                      <Paragraph
                        color="#000"
                        size="14px"
                        style={{ marginTop: 15, marginBottom: 2 }}
                      >
                        Seus cursos estão te esperando, prontos para ajudá-lo a
                        desenvolver novas habilidades, crescer profissionalmente
                        e conquistar mais oportunidades.
                      </Paragraph>
                      <ParagraphBold size="14px" style={{ marginTop: 10 }}>
                        Faça login e continue sua jornada de aprendizado!
                      </ParagraphBold>
                      <ParagraphBold style={{ margin: 0, padding: 0 }}>
                        Email
                      </ParagraphBold>
                      <TextInput
                        name="email"
                        placeholder="Escrever email :"
                        onChange={setFormValue}
                        icon={
                          <FaUser
                            size={18}
                            color="#999"
                            style={{ margin: 5, marginRight: 12 }}
                          />
                        }
                      />
                      <ParagraphBold style={{ marginTop: 10, padding: 0 }}>
                        Palavra Passe
                      </ParagraphBold>
                      <TextInput
                        placeholder="Escrever palavra passe :"
                        type="password"
                        onChange={setFormValue}
                        name="password"
                        icon={
                          <FaLock
                            size={18}
                            color="#999"
                            style={{ margin: 5, marginRight: 12 }}
                          />
                        }
                      />
                      <ParagraphBold size="13px" style={{ marginTop: 10 }}>
                        Esqueceu a senha? Sem problemas!{" "}
                        <Link to={"#"}>Restaure seu acesso </Link> e continue
                        aprendendo.
                      </ParagraphBold>
                      <Button
                        onClick={async () => {
                          reward();
                          await login();
                        }}
                        loading={loading}
                        label="ENTRAR AGORA"
                        color={theme.BACKGROUND.BLUE}
                      />
                      <span id="rewardId" />
                    </ContentForm>
                  </FormLogin>
                </Col>
              </Row>
            </AuthForm>
          </Content>
        </Section>
      </FrontLayout>
    </>
  );
});
export default LoginScreen;
const AuthForm = styled.div`
  width: 100%;
  margin: auto;
  @media ${device.laptopL} {
    width: 80%;
  }
  @media ${device.mobileL} {
    width: 96%;
  }
`;
const ContentImage = styled.div`
  width: 100%;
  items-align: center;
  @media ${device.laptopL} {
    width: 80%;
  }
  @media ${device.mobileL} {
    width: 96%;
  }
`;
const ImageLogin = styled.img`
  width: 75%;
  margin: 10px 4%;
  @media ${device.laptopL} {
    width: 96%;
  }
  @media ${device.mobileL} {
    width: 96%;
  }
`;
const Content = styled.div`
  width: 86%;
  margin: auto;
  @media ${device.laptopL} {
    width: 80%;
  }
  @media ${device.mobileL} {
    width: 96%;
  }
`;
