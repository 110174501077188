const sanitizeForId = (label: string) => {
  return label
    .toLowerCase()
    .replace(/[^\w\s]|(\s+)/g, (_match: string, group1: string) =>
      group1 ? "-" : ""
    );
};
const cutText = (text: string, length: number) => {
  if (typeof text !== "string" || typeof length !== "number" || length < 0) {
    throw new Error(
      "Parâmetros inválidos. Texto deve ser uma string e comprimento um número positivo."
    );
  }
  if (text.length <= length) {
    return text;
  }

  let trimmedText = text.substring(0, length);
  let lastSpaceIndex = trimmedText.lastIndexOf(" ");

  if (lastSpaceIndex > -1) {
    trimmedText = trimmedText.substring(0, lastSpaceIndex);
  }

  return trimmedText + "...";
};

export { sanitizeForId, cutText };
