import React from "react";
import styled from "styled-components";

interface ITextInput extends React.InputHTMLAttributes<HTMLInputElement> {
  defaultValue?: string;
  type?: string;
  required?: boolean;
  placeholder?: string;
  width?: number;
  hasLabel?: boolean;
  label?: string;
  icon?: React.ReactNode;
}
interface IProps {
  width?: number;
}

export default function TextInputSearchMain({
  defaultValue = "",
  placeholder,
  width,
  hasLabel,
  label,
  icon,
  ...rest
}: ITextInput) {
  return (
    <Group width={width}>
      <Input defaultValue={defaultValue} placeholder={placeholder} {...rest} />
      {icon && icon}
    </Group>
  );
}

const Group = styled.div<IProps>`
  width: ${({ width }) => width || 40}%;
  height: 31px;
  border-color: #e0e0e0;
  border-style: solid;
  border-width: 1px;
  margin-top: 8px;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const Input = styled.input`
  width: 80%;
  border: 0;
  font-size: 12px;
  font-family: Montserrat;
  margin-left: 7px;
  outline: none;
`;
