import { observer } from "mobx-react-lite";
import {
  Button,
  ButtonGroup,
  FlexboxGrid,
  Panel,
  Placeholder,
  Steps,
} from "rsuite";
import { Paragraph, Title } from "../../../../../components/Typography";
import Select from "react-select";
import loader from "../../../../../assets/loadding.json";
import React from "react";
import styled from "styled-components";
import { FaBookmark } from "react-icons/fa6";
import TextInputLarge from "../../../../../components/Inputs/TextInputLarge";
import { categoriasCurso } from "../../../../../config/static";
import { useCoursePresenter } from "../presenter";
import Lottie from "react-lottie";
import { useCourseStore } from "../../../../../stores/courseStore";
import { useNavigate } from "react-router-dom";
import { device } from "../../../../../config/devices";
const options = [
  { value: "bySurname", label: "Mais novo" },
  { value: "byIdNumber", label: "Mais Antigo" },
  { value: "byAZ", label: "A-Z" },
  { value: "byZA", label: "Z-A" },
];
const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: loader,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};
const NovoCurso: React.FC = observer(() => {
  const [step, setStep] = React.useState(0);
  const onChange = (nextStep: any) => {
    setStep(nextStep < 0 ? 0 : nextStep > 3 ? 3 : nextStep);
  };

  const navigate = useNavigate();
  const onNext = () => onChange(step + 1);
  const onPrevious = () => onChange(step - 1);
  const { setFormValue, course, setCategoria, loadding, addCourse } =
    useCoursePresenter();
  const { courseSaved } = useCourseStore();
  React.useEffect(() => {
    if (courseSaved) navigate("/dashboard/instrutor");
  }, [courseSaved]);
  return (
    <Content>
      <Form>
        <Steps current={step}>
          <Steps.Item title="Título" />
          <Steps.Item title="Categoria" />
          <Steps.Item title="Para quem ?" />
        </Steps>
        <hr />
        <Content>
          {step == 0 && (
            <>
              <Title
                color="#000"
                style={{ textAlign: "center", marginTop: 10, marginBottom: 10 }}
              >
                Que tal um título de trabalho?
              </Title>
              <Paragraph
                size="15px"
                color="#666"
                style={{ textAlign: "center" }}
              >
                Se você não consegue pensar em um bom título agora, não tem
                problema. Você pode alterá-lo mais tarde.
              </Paragraph>
              <TextInputLarge
                name="titulo"
                onChange={setFormValue}
                defaultValue={course && course.titulo ? course.titulo : ""}
                placeholder="Por Exemplo : Aprenda lógica de programação com Portugol do zero ."
                icon={
                  <FaBookmark
                    size={24}
                    color="#999"
                    style={{ marginTop: 14, marginRight: 10 }}
                  />
                }
              />
            </>
          )}
          {step == 1 && (
            <>
              <Title
                color="#000"
                style={{ textAlign: "center", marginTop: 10, marginBottom: 10 }}
              >
                Que categoria melhor se encaixa no conhecimento que você vai
                compartilhar?
              </Title>
              <Paragraph
                size="15px"
                color="#666"
                style={{ textAlign: "center" }}
              >
                Se você não consegue pensar em um bom título agora, não tem
                problema. Você pode alterá-lo mais tarde.
              </Paragraph>
              <Select
                options={categoriasCurso}
                placeholder="Selecionar uma categoria :"
                onChange={(data) => {
                  const { label, value } = data as {
                    label: string;
                    value: string;
                  };
                  setCategoria(value);
                }}
                defaultValue={categoriasCurso.find(
                  (option) => option.value === course?.categoria
                )}
                styles={{
                  control: (provided: any) => ({
                    ...provided,
                    height: 50,
                    marginTop: 20,
                    marginRight: 10,
                    borderRadius: 0,
                    textAlign: "left",
                    fontFamily: "Montserrat",
                  }),
                  menu: (provided: any) => ({
                    ...provided,
                    zIndex: 3,
                    textAlign: "left",
                  }),
                }}
              />
            </>
          )}
          {step == 2 || step == 3 ? (
            <>
              <Title
                color="#000"
                style={{ textAlign: "center", marginTop: 10, marginBottom: 10 }}
              >
                Para quem desejas compartilhar o conhecimento ?
              </Title>
              <Paragraph
                size="15px"
                color="#666"
                style={{ textAlign: "center" }}
              >
                Se você não consegue pensar em um bom título agora, não tem
                problema. Você pode alterá-lo mais tarde.
              </Paragraph>
              <TextInputLarge
                name="para_quem"
                onChange={setFormValue}
                defaultValue={
                  course && course.para_quem ? course.para_quem : ""
                }
                placeholder="Por Exemplo : para quem deseja aprender Matemática do zero  ."
                icon={
                  <FaBookmark
                    size={24}
                    color="#999"
                    style={{ marginTop: 14, marginRight: 10 }}
                  />
                }
              />
            </>
          ) : (
            ""
          )}
        </Content>
        <hr />
        <ButtonGroup>
          <Button
            onClick={onPrevious}
            disabled={step === 0}
            size="lg"
            color="blue"
            appearance="primary"
          >
            Anterior
          </Button>
          <Button
            onClick={step == 2 ? addCourse : onNext}
            disabled={step === 3}
            size="lg"
            color="blue"
            appearance="primary"
          >
            {loadding ? (
              <Lottie options={defaultOptions} height={25} width={25} />
            ) : (
              "Próximo"
            )}
          </Button>
        </ButtonGroup>
      </Form>
    </Content>
  );
});

export default NovoCurso;

const Form = styled.div`
  margin-top: 80px;
  @media ${device.mobileL} {
    margin-top: 10px;
  }
`;
const Content = styled.div`
  margin: 60px;
  @media ${device.mobileL} {
    margin: 10px;
  }
`;
