import React from "react";
import styled from "styled-components";
import { ParagraphBold } from "../Typography";
import { device } from "../../config/devices";

interface ITextInput extends React.InputHTMLAttributes<HTMLInputElement> {
  defaultValue?: string;
  type?: string;
  required?: boolean;
  placeholder?: string;
  width?: number;
  hasLabel?: boolean;
  label?: string;
  icon?: React.ReactNode;
}
interface IProps {
  width?: number;
}

export default function TextInputLarge({
  defaultValue = "",
  placeholder,
  width,
  hasLabel,
  label,
  icon,
  ...rest
}: ITextInput) {
  return (
    <>
      {hasLabel && (
        <ParagraphBold style={{ marginTop: 10 }}>{label}</ParagraphBold>
      )}
      <Group width={width}>
        <Input
          defaultValue={defaultValue}
          placeholder={placeholder}
          {...rest}
        />
        {icon && icon}
      </Group>
    </>
  );
}

const Group = styled.div<IProps>`
  width: 100%;
  height: 50px;
  background-color: #fff;
  border-color: #999;
  border-style: solid;
  border-width: 1px;
  color: #333;
  font-size: 14px;
  margin-top: 3px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  @media ${device.mobileL} {
    height: 40px;
  }
`;

const Input = styled.input`
  border: 0;
  width: ${({ width }) => width || 100}%;
  margin-left: 7px;
  font-family: Montserrat;
  outline: none;
`;
