import { observer } from "mobx-react-lite";
import { FaHouseUser, FaReact, FaRocket, FaUserGraduate } from "react-icons/fa";
import {
  Link,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import Main from "./main";
import DashboardLayout from "../../layouts/DashboardLayout";
import Instrutor from "./instrutor";
import { Container, FooterDashboard } from "./style";
import NovoCurso from "./instrutor/cursos/novo";
import Manage from "./instrutor/cursos/manage";
import User from "./user";
import { Col, FlexboxGrid, Row } from "rsuite";
import { Paragraph } from "../../components/Typography";
import {
  FaFacebook,
  FaInstagram,
  FaLinkedin,
  FaTiktok,
  FaTwitter,
  FaYoutube,
} from "react-icons/fa6";
import Watch from "./watch";
import { useUserPresenter } from "./user/presenter";
import { useUserStore } from "../../stores/userStore";
import { useEffect } from "react";
const Dashboard: React.FC = observer(() => {
  const { pathname } = useLocation();
  const pathArray = pathname.split("/");
  const navigate = useNavigate();
  const { isLoggedIn } = useUserStore();
  useEffect(() => {
    if (!isLoggedIn) navigate(`/`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <DashboardLayout>
      <Container>
        <Routes>
          <Route path="/watch" element={<Watch />} />
          <Route path="/main" element={<Watch />} />
          <Route path="/instrutor" element={<Instrutor />} />
          <Route path="/instrutor/cursos/novo" element={<NovoCurso />} />
          <Route path="/instrutor/:id_course/manage/*" element={<Manage />} />
          <Route path="/watch/:id_course/" element={<Watch />} />
          <Route path="/watch/:id_course/" element={<Watch />} />
          <Route path="/user/*" element={<User />} />
        </Routes>
        {pathArray[2] && pathArray[2] == "watch" ? (
          <></>
        ) : (
          <FooterDashboard>
            <Row className="show-grid" style={{ margin: 10 }}>
              <Col xxl={14} xl={14} md={14} xs={24}>
                <Paragraph color="#000" size="14px" style={{ marginTop: 10 }}>
                  © 2025{" "}
                  <Link to={""}>
                    <strong>Rozacademy</strong>.
                  </Link>{" "}
                  Todos direitos reservados.
                </Paragraph>
              </Col>
              <Col xxl={10} xl={10} md={10} xs={24}>
                <FlexboxGrid justify="end" style={{ marginTop: 10 }}>
                  <FlexboxGrid.Item colspan={4}>
                    <Link to={""}>
                      <FaTiktok color="#999" size={30} />
                    </Link>
                  </FlexboxGrid.Item>
                  <FlexboxGrid.Item colspan={4}>
                    <Link to={""}>
                      <FaInstagram color="#999" size={30} />
                    </Link>
                  </FlexboxGrid.Item>
                  <FlexboxGrid.Item colspan={4}>
                    <Link to={""}>
                      <FaFacebook color="#999" size={30} />
                    </Link>
                  </FlexboxGrid.Item>
                  <FlexboxGrid.Item colspan={4}>
                    <Link to={""}>
                      <FaLinkedin color="#999" size={30} />
                    </Link>
                  </FlexboxGrid.Item>
                  <FlexboxGrid.Item colspan={3}>
                    <Link to={""}>
                      <FaYoutube color="#999" size={30} />
                    </Link>
                  </FlexboxGrid.Item>
                </FlexboxGrid>
              </Col>
            </Row>
          </FooterDashboard>
        )}
      </Container>
    </DashboardLayout>
  );
});

export default Dashboard;
