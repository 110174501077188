import { observer } from "mobx-react-lite";
import { Content } from "../style";
import { Title } from "../../../components/Typography";
import { Filter } from "../../../components/Inputs/Filter";
import { Col, FlexboxGrid, Row } from "rsuite";
import Button from "../../../components/Buttons/Button";
import theme from "../../../theme";
import { useNavigate } from "react-router-dom";
import { useCourseStore } from "../../../stores/courseStore";
import { useCoursePresenter } from "./cursos/presenter";
import CourseTable from "../../../components/Tables/CourseTable";
import styled from "styled-components";
import GridCourse from "../../../components/Grid/GridCourse";
const options = [
  { value: "bySurname", label: "Mais novo" },
  { value: "byIdNumber", label: "Mais Antigo" },
  { value: "byAZ", label: "A-Z" },
  { value: "byZA", label: "Z-A" },
];
const Instrutor: React.FC = observer(() => {
  const navigate = useNavigate();
  const { resetCourseSaved } = useCourseStore();
  const { courseListInstrutor, resetStoreValues } = useCoursePresenter();
  return (
    <Content>
      <Row>
        <Title color="#000" style={{ marginTop: 10, marginLeft: 5 }}>
          Meus cursos
        </Title>
        <Col xxl={12} xl={14} lg={14} md={15} sm={24} xs={24}>
          <Filter options={options} />
        </Col>
        <Col xxl={12} xl={10} lg={10} md={9} sm={24} xs={24}>
          <Button
            label="Criar novo curso "
            color={theme.BACKGROUND.BLUE}
            onClick={() => {
              resetStoreValues();
              resetCourseSaved();
              navigate("/dashboard/instrutor/cursos/novo");
            }}
          />
        </Col>
      </Row>
      <ContentTable>
        {courseListInstrutor && <GridCourse data={courseListInstrutor} />}
      </ContentTable>
    </Content>
  );
});

export default Instrutor;
const ContentTable = styled.div`
  margin-top: 20px;
`;
