import { observer } from "mobx-react-lite";
import styled from "styled-components";
import theme from "../../../theme";
import { device } from "../../../config/devices";
import { Section } from "../style";
import { Breadcrumb, Col, FlexboxGrid, Rate, Row, Tabs } from "rsuite";
import { useCoursePresenter } from "../../dashboard/instrutor/cursos/presenter";
import { Link, useNavigate, useParams } from "react-router-dom";
import React from "react";
import { Drawers as Drawer, useUiStore } from "../../../stores/uiStore";
import { Drawers } from "../../../components";
import {
  Paragraph,
  ParagraphBold,
  Strong,
  Title,
} from "../../../components/Typography";
import { FaVolumeLow } from "react-icons/fa6";
import Button from "../../../components/Buttons/Button";

import { useReward } from "react-rewards";
import { useAuthPresenter } from "../presenter";
import { useUserStore } from "../../../stores/userStore";
import { useUPurshasedCourseStore } from "../../../stores/purchasedCourseStore";
const Checkout: React.FC = observer(() => {
  const { reward, isAnimating } = useReward("rewardId", "confetti");
  const {
    getCourseId,
    course,
    loadding,
    getFormatCurrent,
    checkPurchasedCourse,
    addPurchased,
    purchased,
  } = useCoursePresenter();
  const { id_course } = useParams();
  const { isLoggedIn } = useUserStore();
  const { loading, resetStoreValuePurchased, setCourseId } = useAuthPresenter();
  const { purchasedSaved, firstPurchasedList } = useUPurshasedCourseStore();
  const navigate = useNavigate();
  React.useEffect(() => {
    getCourseId(id_course as string);
    setCourseId(id_course as string);
  }, []);
  React.useEffect(() => {
    if (purchasedSaved) {
      navigate(
        `/dashboard/user/meu-percurso/${firstPurchasedList[0]?.external_id}/payment`
      );
      resetStoreValuePurchased();
    }
  }, [purchasedSaved]);
  React.useEffect(() => {
    if (!isLoggedIn) navigate(`/`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <Section>
        {course && (
          <Content>
            <Row className="show-grid">
              <Col xs={24} xl={14} xxl={24} md={24} sm={24} lg={13}>
                <GridAboutCourse>
                  <ParagraphBold
                    size={"16px"}
                    color="#000"
                    style={{ marginTop: 10 }}
                  >
                    🚀 Falta apenas um passo para sua transformação
                    profissional!
                  </ParagraphBold>
                  <Paragraph size={"14px"}>
                    Você chegou até aqui porque sabe o valor do conhecimento.
                    Agora, é hora de dar o próximo passo e garantir seu acesso
                    ao curso que pode abrir novas portas na sua carreira.
                  </Paragraph>

                  <hr />
                  <FlexboxGrid
                    justify="space-between"
                    style={{ marginTop: 10, marginBottom: 10, marginLeft: 0 }}
                  >
                    <FlexboxGrid.Item colspan={5}>
                      <CardImgProfile>
                        <img src={course.img_url} />
                      </CardImgProfile>
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item colspan={19}>
                      <ParagraphBold
                        size="14px"
                        style={{
                          textAlign: "left",
                          marginTop: 0,
                          marginBottom: 0,
                        }}
                      >
                        {course.titulo}
                      </ParagraphBold>
                      <Paragraph size="14px">
                        <FaVolumeLow color="#999" style={{ marginRight: 10 }} />{" "}
                        Áudio: Português
                      </Paragraph>
                      <ParagraphBold color={theme.BACKGROUND.GREEN}>
                        {course?.price
                          ? `${getFormatCurrent(course?.price as number)} Kz`
                          : "Grátis"}{" "}
                      </ParagraphBold>
                    </FlexboxGrid.Item>
                  </FlexboxGrid>
                </GridAboutCourse>
              </Col>
              <Col xs={24} xl={10} xxl={10} md={24} sm={24} lg={11}>
                <GridDetailCourse id="infocourse">
                  <ParagraphBold style={{ margin: "10px 20px" }} size={"14px"}>
                    Finalizar compra
                  </ParagraphBold>
                  <Paragraph size={"13px"} style={{ margin: "10px 20px" }}>
                    {" "}
                    O sucesso pertence a quem age! Não adie mais seus
                    objetivos—clique no botão abaixo e finalize sua inscrição
                    agora.
                  </Paragraph>
                  <Paragraph style={{ margin: "0 4%" }}>
                    {checkPurchasedCourse() ? (
                      <Button
                        label="CURSO JÁ ADQUIRIDO "
                        width="100"
                        onClick={() => {
                          navigate("/dashboard/user/meu-percurso");
                        }}
                        color={theme.BACKGROUND.BLUE_OCEAN}
                      />
                    ) : (
                      <Button
                        label="FINALIZAR COMPRA"
                        width="100"
                        color={theme.BACKGROUND.BLACK}
                        onClick={async () => {
                          reward();
                          await addPurchased();
                        }}
                        loading={loadding}
                      />
                    )}
                  </Paragraph>
                  <span id="rewardId" />
                </GridDetailCourse>
              </Col>
            </Row>
          </Content>
        )}
      </Section>
      <Drawers.Payment />
    </>
  );
});
export default Checkout;
interface IProps {
  backgroundImage?: string;
}
const CardBreadcrumb = styled.div`
  width: 100%;
  height: 50px;
  border-bottom: 1px solid #e0e0e0;
`;

const Content = styled.div`
  width: 76%;
  margin: auto;
  @media ${device.mobileL} {
    width: 92%;
    margin: auto;
    padding: 0;
  }
`;
const GridAboutCourse = styled.div`
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
  h1 {
    font-family: Montserrat;
  }
`;
const GridDetailCourse = styled.div`
  width: 96%;
  margin: 10px auto;
  background-color: #fff;
  padding: 10px 0px;
  border: 1px solid #e0e0e0;
`;

const CardImgProfile = styled.div`
  width: 70px;
  height: 70px;
  border: 1px solid #e9e9e9;
  border-radius: 50%;
  img {
    width: 60px;
    height: 60px;
    margin: 5px;
    border-radius: 50%;
  }
`;
