import { observer } from "mobx-react-lite";
import FrontLayout from "../../../layouts/FrontLayout";
import {
  H1,
  Paragraph,
  ParagraphBold,
  Title,
  TitleLarge,
} from "../../../components/Typography";
import styled, { keyframes } from "styled-components";
import { bgvideo } from "../../../images";
import theme from "../../../theme";
import { device } from "../../../config/devices";
import {
  FaBriefcase,
  FaChartPie,
  FaDesktop,
  FaRocket,
  FaUserGraduate,
  FaUserTie,
} from "react-icons/fa";

import ReactPlayer from "react-player";
import loader from "../../../assets/home.json";
import Carousel from "react-multi-carousel";
import { useCoursePresenter } from "../../dashboard/instrutor/cursos/presenter";
import { Link, useNavigate } from "react-router-dom";
import { cutText } from "../../../util/stringUtils";
import { FaLanguage } from "react-icons/fa6";
import ButtonBorder from "../../../components/Buttons/ButtonBorder";
import { Rate } from "rsuite";

const categorias = [
  {
    label: "Desenvolvimento & programação",
    icon: <FaDesktop size={24} />,
    color: "#e61336",
    path: "desenvolvimento-programacao",
  },
  {
    label: "Acadêmico",
    icon: <FaUserGraduate size={24} />,
    color: "#f5d611",
    path: "academico",
  },
  {
    label: "Idioma",
    icon: <FaLanguage size={24} />,
    color: "#9932a8",
    path: "idioma",
  },
  {
    label: "Administração Pública",
    icon: <FaBriefcase size={24} />,
    color: "#09e3b0",
    path: "administracao-publica",
  },
  {
    label: "Finanças & contabilidade",
    icon: <FaChartPie size={24} />,
    color: "#e36f09",
    path: "financas-contabilidade",
  },
  {
    label: "Produtividade no escritório",
    icon: <FaRocket size={24} />,
    color: "#55f511",
    path: "produtividade-no-escritorio",
  },
  {
    label: "Desenvolvimento pessoal",
    icon: <FaUserTie size={24} />,
    color: "#11b1f5",
    path: "desenvolvimento-pessoal",
  },
];
const responsive1 = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 5,
  },
  tablet: {
    breakpoint: { max: 1024, min: 690 },
    items: 4,
  },
  mobile: {
    breakpoint: { max: 690, min: 0 },
    items: 3,
  },
};
const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 2000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 2000, min: 954 },
    items: 4,
  },
  tablet: {
    breakpoint: { max: 954, min: 480 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 480, min: 0 },
    items: 1,
  },
};
const Home: React.FC = observer(() => {
  const { resetStoreValues, courseList, getFormatCurrent, loadding } =
    useCoursePresenter();
  const navigate = useNavigate();
  return (
    <>
      <FrontLayout>
        <HeroSectionContainer>
          <VideoBackground>
            <ReactPlayer
              url={bgvideo} // Substitua pelo link do seu vídeo
              playing
              loop
              muted
              width="100%"
              height="100%"
              style={{
                position: "fixed",
                top: 0,
                left: "0",
                background: "#000",
              }}
            />
          </VideoBackground>
          <Overlay /> {/* Camada preta com opacidade */}
          <HeroContent>
            <TitleLarge color="#fff">
              Comece sua trajetória de aprendizado na Rozacademy !
            </TitleLarge>
            <Paragraph
              style={{ textAlign: "left", marginTop: 15, marginBottom: 10 }}
              color="#fff"
              size={"16px"}
            >
              Aprenda no seu próprio ritmo, sem prazos ou limitações, e alcance
              seus objetivos com autonomia e flexibilidade.
            </Paragraph>
            <SearchBox>
              <input type="text" placeholder="Pesquise cursos..." />
              <button onClick={() => navigate("/search")}>Pesquisar</button>
            </SearchBox>
            <Carousel
              responsive={responsive1}
              ssr={true} // means to render carousel on server-side.
              infinite={true}
              autoPlay={true}
            >
              {categorias.map((categoria, index) => (
                <MainButton
                  color={categoria.color}
                  key={index}
                  onClick={() => navigate(`/categoria/${categoria.path}`)}
                >
                  {categoria.icon}
                  <ParagraphBold color="#fff" size={"14px"}>
                    {categoria.label}
                  </ParagraphBold>
                </MainButton>
              ))}
            </Carousel>
          </HeroContent>
        </HeroSectionContainer>
        <HomeContainer>
          {courseList && (
            <PopularCoursesSection>
              <Carousel
                responsive={responsive}
                ssr={true} // means to render carousel on server-side.
                infinite={true}
                autoPlay={true}
              >
                {courseList.map((item, i) => (
                  <CourseCard key={i}>
                    <CourseImage src={item.img_url} alt={`Curso ${i + 1}`} />
                    <CourseTitle>
                      <Paragraph
                        style={{ textAlign: "left", margin: "10px 0px" }}
                      >
                        <Rate readOnly defaultValue={5} color="yellow" />
                      </Paragraph>
                      <ParagraphBold size={"15px"}>
                        {cutText(item.titulo as string, 65)}
                      </ParagraphBold>
                    </CourseTitle>
                    <CourseInfo>
                      <CourseInstructor>
                        <ParagraphBold
                          color="#fff"
                          size={"15px"}
                          style={{ textAlign: "center" }}
                        >
                          {item.users?.name}
                        </ParagraphBold>
                      </CourseInstructor>
                      <CoursePrice>
                        <ParagraphBold
                          style={{ textAlign: "center" }}
                          size={"17px"}
                          color={theme.BACKGROUND.GREEN}
                        >
                          {item?.price
                            ? `${getFormatCurrent(item?.price as number)} Kz`
                            : "Grátis"}
                        </ParagraphBold>
                      </CoursePrice>
                      <ButtonBorder
                        color="#fff"
                        onClick={() => {
                          resetStoreValues();
                          navigate(`/cursos/${item.external_id}/detail`);
                        }}
                        width="98%"
                        label="CONHECER CURSO"
                      />
                    </CourseInfo>
                  </CourseCard>
                ))}
              </Carousel>
            </PopularCoursesSection>
          )}
        </HomeContainer>
      </FrontLayout>
    </>
  );
});
export default Home;
const HomeContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  color: #fff;
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7); /* Cor preta com opacidade */
  z-index: -1; /* Coloca a camada acima do vídeo, mas abaixo do conteúdo */
`;
const HeroSectionContainer = styled.section`
  position: relative;
  width: 100%;
  height: 100vh; /* Altura total da tela */
  overflow: hidden;
  @media ${device.tablet} {
    height: 470px;
  }
`;

const VideoBackground = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  width: 100%;
  height: 100%;
  z-index: -1; /* Coloca o vídeo atrás do conteúdo */
`;

const HeroContent = styled.div`
  position: relative;
  z-index: 1; /* Garante que o conteúdo fique acima do vídeo */
  max-width: 900px;
  margin: 0 auto;
  padding: 120px 40px;
  text-align: center;
  color: #fff;
  @media ${device.laptopL} {
    padding: 90px 40px;
  }
  @media ${device.tablet} {
    padding: 30px 20px;
  }
`;

const SearchBox = styled.div`
  display: flex;
  align-items: center;
  background-color: #fff;
  overflow: hidden;
  margin-top: 15px;
  margin-bottom: 30px;
  input {
    flex: 1;
    padding: 15px;
    border: none;
    outline: none;
    background: none;
    color: #000;
    font-family: Montserrat;

    &::placeholder {
      color: #666;
    }
  }

  button {
    padding: 15px 20px;
    background-color: ${theme.BACKGROUND.BLUE};
    border: none;
    color: #fff;
    cursor: pointer;
    font-family: Montserrat;
    font-weight: bold;
    &:hover {
      background-color: ${theme.BACKGROUND.BLUE};
    }
  }
`;

const MainButton = styled.button<{ color: string }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 150px;
  height: 150px;
  background-color: ${(props) => props.color};
  color: #fff;
  border: none;
  border-radius: 0px;
  cursor: pointer;
  transition: transform 0.3s ease;
  p {
    margin-top: 10px;
    text-align: center;
    @media ${device.mobileL} {
      font-size: 11px;
      line-height: 11px;
    }
  }

  &:hover {
    transform: scale(1.1);
  }
  @media ${device.mobileL} {
    width: 100px;
    height: 100px;
  }
`;

const PopularCoursesSection = styled.section`
  width: 100%;
  padding: 0px 20px;
`;
const CourseInfo = styled.div`
  padding: 15px;
  background-color: rgba(0, 0, 0, 0.9);
  color: #fff;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  transform: translateY(100%);
  opacity: 0;
  transition: transform 0.3s ease, opacity 0.3s ease;

  p {
    margin: 5px 0;
  }
`;

const CourseCard = styled.div`
  background-color: #fff;
  border-radius: 0px;
  overflow: hidden;
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  position: relative;
  cursor: pointer;
  min-height: 300px; /* Altura fixa para uniformizar os cartões */
  display: flex;
  flex-direction: column; /* Alinha os elementos verticalmente */
  justify-content: space-between; /* Espaça os elementos uniformemente */

  &:hover {
    transform: scale(1.09);
    box-shadow: 0 0px 0px rgba(0, 0, 0, 0.4);
  }

  &:hover ${CourseInfo} {
    opacity: 1;
    transform: translateY(0);
  }
`;

const CourseImage = styled.img`
  width: 100%;
  height: 200px; /* Altura fixa para a imagem */
  object-fit: cover;
  transition: opacity 0.3s ease;

  ${CourseCard}:hover & {
    opacity: 0.8;
  }
`;

const CourseTitle = styled.div`
  padding: 20px;
  font-size: 1rem;
  font-weight: bold;
  color: #000;
`;

const CourseInstructor = styled.p`
  font-size: 0.9rem;
  color: #ccc;
`;

const CoursePrice = styled.p`
  font-size: 1rem;
  color: ${theme.BACKGROUND.GREEN};
  font-weight: bold;
`;
