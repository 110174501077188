import React, { ReactElement, useRef, useState } from "react";
import styled from "styled-components";
import TabList from "./TabList";
import TabItem from "./TabItem";
import ReactCrop, {
  centerCrop,
  makeAspectCrop,
  Crop,
  PixelCrop,
} from "react-image-crop";
import {
  Avatar,
  Col,
  FlexboxGrid,
  HStack,
  InlineEdit,
  Input,
  List,
  Row,
} from "rsuite";
import { Paragraph, ParagraphBold } from "../Typography";
import {
  FaFacebook,
  FaImage,
  FaInstagram,
  FaLink,
  FaLinkedin,
  FaListOl,
  FaUserInjured,
  FaUserLarge,
  FaUserPen,
  FaVideo,
  FaXTwitter,
  FaYoutube,
} from "react-icons/fa6";
import { Modals } from "../../components";
import { observer } from "mobx-react-lite";
import { MdClose, MdHelp, MdHelpCenter } from "react-icons/md";
import loader from "../../assets/teach (2).json";
import loader1 from "../../assets/video.json";
import loader2 from "../../assets/help.json";
import Lottie from "react-lottie";
import { useUserStore } from "../../stores/userStore";
import { useUserPresenter } from "../../pages/dashboard/user/presenter";
import { useDebounceEffect } from "../canva/useDebounceEffect";
import { canvasPreview } from "../canva/previewCanva";
import TextInputLarge from "../Inputs/TextInputLarge";
import { ButtonGroup } from "../../pages/dashboard/style";
import Button from "../Buttons/Button";
import theme from "../../theme";
import { FaRegCheckSquare } from "react-icons/fa";
function centerAspectCrop(
  mediaWidth: number,
  mediaHeight: number,
  aspect: number
) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: "%",
        width: 100,
      },
      aspect,
      mediaWidth,
      mediaHeight
    ),
    mediaWidth,
    mediaHeight
  );
}
const TO_RADIANS = Math.PI / 180;
const defaultOptions1 = {
  loop: true,
  autoplay: true,
  animationData: loader,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};
const defaultOptions2 = {
  loop: true,
  autoplay: true,
  animationData: loader1,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};
const defaultOptions3 = {
  loop: true,
  autoplay: true,
  animationData: loader2,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};
const TabEditProfile = observer(() => {
  const { imgSrc, setImgSrc, loadding, uploadImageProfile } =
    useUserPresenter();
  const { user } = useUserStore();
  const previewCanvasRef = useRef<HTMLCanvasElement>(null);
  const [aspect] = useState<number | undefined>(10 / 10);
  const imgRef = useRef<HTMLImageElement>(null);
  const [completedCrop, setCompletedCrop] = useState<PixelCrop>();
  const [crop, setCrop] = useState<Crop>();
  const [scale] = useState(1);
  const [rotate] = useState(0);

  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        // We use canvasPreview as it's much faster than imgPreview.
        canvasPreview(
          imgRef.current,
          previewCanvasRef.current,
          completedCrop,
          scale,
          rotate
        );
      }
    },
    100,
    [completedCrop, scale, rotate]
  );
  function onSelectFile(e: React.ChangeEvent<HTMLInputElement>) {
    if (e.target.files && e.target.files.length > 0) {
      setCrop(undefined); // Makes crop preview update between images.
      const reader = new FileReader();
      reader.addEventListener("load", () =>
        setImgSrc(reader.result?.toString() || "")
      );
      reader.readAsDataURL(e.target.files[0]);
    }
  }
  function onImageLoad(e: React.SyntheticEvent<HTMLImageElement>) {
    if (aspect) {
      const { width, height } = e.currentTarget;
      setCrop(centerAspectCrop(width, height, aspect));
    }
  }
  async function uploadImage() {
    const ctx = previewCanvasRef.current
      ? previewCanvasRef.current.getContext("2d")
      : null;
    const image = imgRef.current;
    const canvas = previewCanvasRef.current;
    if (!ctx) {
      throw new Error("No 2d context");
    }
    const scaleX = image ? image?.naturalWidth / image?.width : null;
    const scaleY = image ? image?.naturalHeight / image?.height : null;
    const pixelRatio = window.devicePixelRatio;
    if (canvas && completedCrop && scaleX && scaleY && image) {
      canvas.width = Math.floor(completedCrop?.width * scaleX * pixelRatio);
      canvas.height = Math.floor(completedCrop?.height * scaleY * pixelRatio);

      ctx.scale(pixelRatio, pixelRatio);
      ctx.imageSmoothingQuality = "high";

      const cropX = completedCrop.x * scaleX;
      const cropY = completedCrop.y * scaleY;

      const rotateRads = rotate * TO_RADIANS;
      const centerX = image.naturalWidth / 2;
      const centerY = image.naturalHeight / 2;
      ctx.save();

      // 5) Move the crop origin to the canvas origin (0,0)
      ctx.translate(-cropX, -cropY);
      // 4) Move the origin to the center of the original position
      ctx.translate(centerX, centerY);
      // 3) Rotate around the origin
      ctx.rotate(rotateRads);
      // 2) Scale the image
      ctx.scale(scale, scale);
      // 1) Move the center of the image to the origin (0,0)
      ctx.translate(-centerX, -centerY);
      ctx.drawImage(
        image,
        0,
        0,
        image.naturalWidth,
        image.naturalHeight,
        0,
        0,
        image.naturalWidth,
        image.naturalHeight
      );
      if (previewCanvasRef.current) {
        previewCanvasRef.current.toBlob(
          (blob: any) => {
            const newImage = new File([blob], blob.name, { type: blob.type });
            uploadImageProfile(newImage);
          },
          "image/jpg",
          1
        );
      }
    }
  }
  return (
    <>
      <TabList activeTabIndex={0}>
        <TabItem label="Editar informações de perfil" icon={<FaUserLarge />}>
          <Row>
            <Col
              lg={24}
              xl={24}
              xxl={24}
              md={24}
              sm={24}
              style={{ padding: 0, margin: 0 }}
            >
              <ParagraphBold size="20px" style={{ marginBottom: 10 }}>
                <FaUserPen size={26} /> Editar Informações de perfil
              </ParagraphBold>
              <Paragraph size={"15px"}>
                Manter seu perfil atualizado é essencial para garantir uma
                experiência completa na plataforma. A seção de{" "}
                <strong>edição das informações de perfil</strong> permite que
                você personalize suas informações pessoais, facilitando sua
                identificação e interação com outros usuários.
              </Paragraph>
              <Row className="show-grid" style={{ marginTop: 17 }}>
                <Col lg={12} xl={12} xxl={12} md={24} sm={24} xs={24}>
                  <ParagraphBold>Nome completo</ParagraphBold>
                  <TextInputLarge
                    defaultValue={user?.url}
                    placeholder="Nome"
                    icon={
                      <FaUserLarge
                        size={20}
                        color="#999"
                        style={{ margin: 14 }}
                      />
                    }
                  />
                </Col>
                <Col lg={12} xl={12} xxl={12} md={24} sm={24} xs={24}>
                  <ParagraphBold>Profissão ou título</ParagraphBold>
                  <TextInputLarge
                    defaultValue={user?.url}
                    placeholder="Profissional ou título"
                    icon={
                      <FaUserInjured
                        size={20}
                        color="#999"
                        style={{ margin: 14 }}
                      />
                    }
                  />
                </Col>
                <Col lg={12} xl={12} xxl={12} md={24} sm={24} xs={24}>
                  <ParagraphBold>Site</ParagraphBold>
                  <TextInputLarge
                    defaultValue={user?.url}
                    placeholder="URL"
                    icon={
                      <FaLink size={20} color="#999" style={{ margin: 14 }} />
                    }
                  />
                </Col>
                <Col lg={12} xl={12} xxl={12} md={24} sm={24} xs={24}>
                  <ParagraphBold>Site</ParagraphBold>
                  <TextInputLarge
                    defaultValue={user?.url}
                    placeholder="Link do perfil no instagram"
                    icon={
                      <FaInstagram
                        size={20}
                        color="#999"
                        style={{ margin: 14 }}
                      />
                    }
                  />
                </Col>
                <Col lg={12} xl={12} xxl={12} md={24} sm={24} xs={24}>
                  <ParagraphBold>Twitter</ParagraphBold>
                  <TextInputLarge
                    defaultValue={user?.url}
                    placeholder="Link do perfil no X :"
                    icon={
                      <FaXTwitter
                        size={20}
                        color="#999"
                        style={{ margin: 14 }}
                      />
                    }
                  />
                </Col>
                <Col lg={12} xl={12} xxl={12} md={24} sm={24} xs={24}>
                  <ParagraphBold>Facebook</ParagraphBold>
                  <TextInputLarge
                    defaultValue={user?.url}
                    placeholder="Link do perfil no Facebook"
                    icon={
                      <FaFacebook
                        size={20}
                        color="#999"
                        style={{ margin: 14 }}
                      />
                    }
                  />
                </Col>
                <Col lg={12} xl={12} xxl={12} md={24} sm={24} xs={24}>
                  <ParagraphBold>LinkedIn</ParagraphBold>
                  <TextInputLarge
                    defaultValue={user?.url}
                    placeholder="Link do perfil no LinkedIn"
                    icon={
                      <FaLinkedin
                        size={20}
                        color="#999"
                        style={{ margin: 14 }}
                      />
                    }
                  />
                </Col>
                <Col lg={12} xl={12} xxl={12} md={24} sm={24} xs={24}>
                  <ParagraphBold>Youtube</ParagraphBold>
                  <TextInputLarge
                    defaultValue={user?.url}
                    placeholder="Link do perfil no Youtube"
                    icon={
                      <FaYoutube
                        size={20}
                        color="#999"
                        style={{ margin: 14 }}
                      />
                    }
                  />
                </Col>
              </Row>
              <ParagraphBold>Biografia</ParagraphBold>
              <InlineEdit
                placeholder="Biografia :"
                style={{
                  marginBottom: 20,
                  paddingTop: 7,
                  fontFamily: "Montserrat",
                  minHeight: 50,
                  width: "100%",
                  borderWidth: 1,
                  borderStyle: "solid",
                  borderColor: "#d3d3d3",
                }}
              >
                <Input as="textarea" rows={5} />
              </InlineEdit>
            </Col>
            <Col
              lg={12}
              xl={12}
              xxl={12}
              md={24}
              sm={24}
              xs={24}
              style={{ padding: 0, margin: 0 }}
            ></Col>

            <ButtonGroup>
              <Button
                label="Salvar Informações"
                width="30"
                color={theme.BACKGROUND.BLUE}
              />
            </ButtonGroup>
          </Row>
        </TabItem>
        <TabItem label="Fotografia de perfil" icon={<FaImage />}>
          <Row className="show-grid" style={{ marginTop: 17 }}>
            <Col lg={12} xl={12} xxl={12} md={24} sm={24}>
              <ParagraphBold size="20px" style={{ marginBottom: 20 }}>
                <FaUserPen size={26} />
                {"  "} Alterar foto de perfil
              </ParagraphBold>
              <Paragraph size="14px">
                Faça o upload da sua imagem de perfil aqui, garantindo que ela
                atenda aos nossos padrões de qualidade para aprovação. Para
                isso, siga estas diretrizes:
              </Paragraph>
              <Paragraph size="14px">
                <FaRegCheckSquare /> Dimensão mínima: 750 x 422 pixels, para
                garantir boa resolução e qualidade visual.
              </Paragraph>
              <Paragraph size="14px">
                <FaRegCheckSquare />
                Formatos compatíveis: .jpg, .jpeg, .gif ou .png. Certifique-se
                de que sua imagem esteja em um desses formatos para evitar erros
                no envio.
              </Paragraph>
              <Paragraph size="14px">
                <MdClose color={theme.BACKGROUND.RED_THEME} size={18} />
                Sem textos na imagem: Não são permitidas imagens com textos,
                logotipos ou marcas d’água, garantindo um visual mais
                profissional e uniforme.
              </Paragraph>
              <ParagraphBold>
                Verifique esses requisitos antes do upload para evitar rejeições
                e garantir que sua foto seja aprovada rapidamente.
              </ParagraphBold>
              <ParagraphBold>Carregar a foto de perfil</ParagraphBold>
              <ButtonUpload accept="image/*" onChange={onSelectFile} />
              <Button label="Selecionar imagem do curso " />
            </Col>
            <Col lg={12} xl={12} xxl={12} md={24} sm={24}>
              <CardImgProfile>
                {imgSrc ? (
                  <ReactCrop
                    crop={crop}
                    onChange={(_, percentCrop) => setCrop(percentCrop)}
                    onComplete={(c) => {
                      setCompletedCrop(c);
                    }}
                    aspect={aspect}
                    minWidth={470}
                    minHeight={300}
                    circularCrop
                  >
                    <img
                      ref={imgRef}
                      alt="Crop me"
                      src={imgSrc}
                      onLoad={onImageLoad}
                    />
                  </ReactCrop>
                ) : (
                  <ImageBackground>
                    {user?.img_url ? (
                      <Image src={user?.img_url} />
                    ) : (
                      <Avatar circle />
                    )}
                  </ImageBackground>
                )}
              </CardImgProfile>
              {imgSrc && (
                <Button
                  label="Salvar imagem"
                  onClick={uploadImage}
                  loading={loadding}
                  color={theme.BACKGROUND.BLUE}
                />
              )}
              {!!completedCrop && (
                <>
                  <div>
                    <canvas
                      ref={previewCanvasRef}
                      id="canva"
                      style={{
                        border: "1px solid black",
                        objectFit: "contain",
                        width: completedCrop.width,
                        height: completedCrop.height,
                        display: "none",
                      }}
                    />
                  </div>
                </>
              )}
            </Col>
          </Row>
        </TabItem>
      </TabList>
      <Modals.AddQuetionModal />
    </>
  );
});
export default TabEditProfile;

const ButtonUpload = styled.input.attrs({ type: "file" })`
  appearance: none;
  opacity: 0;
  width: 100%;
  height: 50px;
  cursor: pointer;
  position: absolute;
  margin-top: 10px;
`;
const ImageBackground = styled.div`
  width: 100%;
  min-height: 300px;
  background-color: #f0f0f0;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const Image = styled.img`
  width: 70%;
  border-radius: 50%;
  margin: 10px;
`;
const CardImgProfile = styled.div`
  width: 95%;
  min-height: 300px;
  padding: 10px;
`;
