import styled from "styled-components";
import { device } from "../config/devices";

interface IProps {
  center?: boolean;
  size?: string | number;
  font?: number;
}

export const Title = styled.h1<IProps>`
  font-style: normal;
  color: ${({ color }) => (color ? color : "#000")};
  text-align: ${({ center }) => (center ? "center" : "left")};
  line-height: 30px;
  font-size: ${({ size }) => (size ? size : "20px")};
  font-family: Montserrat;
  @media ${device.mobileL} {
    font-size: 15px;
  }
`;
export const TitleLarge = styled.h1<IProps>`
  font-style: normal;
  color: ${({ color }) => (color ? color : "#000")};
  text-align: ${({ center }) => (center ? "center" : "left")};
  line-height: 40px;
  font-family: Montserrat;
  @media ${device.laptopL} {
  }
  @media ${device.tablet} {
    font-size: 30px;
    line-height: 30px;
  }
`;
export const H5 = styled.h5<IProps>`
  font-family: "Questrial", sans-serif;
  text-align: ${({ color }) => (color ? color : "#0000")};
  @media ${device.laptopL} {
    font-size: 25px;
  }
`;
export const H1 = styled.h5<IProps>`
  font-family: Montserrat;
  color: ${({ color }) => (color ? color : "#000")};
  font-size: 40px;
  line-height: 100%;
  @media ${device.laptopL} {
    font-size: 64px;
  }
  @media ${device.tablet} {
    font-size: 30px;
  }
`;
export const H6 = styled.h6<IProps>`
  font-family: "Questrial", sans-serif;
  @media ${device.laptopL} {
    font-size: 20px;
  }
`;

export const Subtitle = styled.p<IProps>`
  font-style: normal;
  text-align: left;
  font-weight: bold;
  color: ${({ color }) => (color ? color : "#000")};
  font-size: ${({ size }) => (size ? size : "12px")};
  font-family: Montserrat;
  @media ${device.laptopL} {
    font-size: 17px;
  }
  @media ${device.tablet} {
    font-size: 17px;
  }
`;

export const Paragraph = styled.p<IProps>`
  font-style: normal;
  text-align: justify;
  text-justify: inter-word;
  color: ${({ color }) => (color ? color : "#000")};
  font-size: ${({ size }) => (size ? size : "12px")};
  font-family: Montserrat;
  @media ${device.laptopL} {
    font-size: 14px;
  }
  @media ${device.tablet} {
    font-size: 13px;
  }
`;
export const ParagraphBold = styled.p<IProps>`
  font-style: normal;
  text-align: left;
  font-weight: bold;
  color: ${({ color }) => (color ? color : "#000")};
  font-size: ${({ size }) => (size ? size : "12px")};
  font-family: Montserrat;
  line-height: 25px;
  font-size: ${({ size }) => (size ? size : "12px")};
  @media ${device.laptopL} {
    font-size: 16px;
  }
  @media ${device.mobileL} {
  }
`;

export const Strong = styled.strong<IProps>`
  color: ${({ color }) => (color ? color : "#000")};
  font-size: ${({ size }) => (size ? size : "12px")};
  font-family: Montserrat;
  margin-top: 0;
`;

export const CardHeader = styled.p<IProps>`
  font-family: "Questrial", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 23px;
  text-align: left;
  color: #686767;
  @media ${device.laptopL} {
    font-size: 16px;
  }
`;
