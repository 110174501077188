import { observer } from "mobx-react-lite";
import FrontLayout from "../../../layouts/FrontLayout";
import styled from "styled-components";
import { bg, instrutor, main } from "../../../images";
import theme from "../../../theme";
import { device } from "../../../config/devices";
import { Modals as Modal, useUiStore } from "../../../stores/uiStore";
import {
  FaBriefcase,
  FaChalkboardTeacher,
  FaChartPie,
  FaDesktop,
  FaDraftingCompass,
  FaMoneyBillAlt,
  FaRocket,
  FaServer,
  FaUserGraduate,
  FaUserTie,
} from "react-icons/fa";
import {
  ButtonPayCourse,
  CardCourse,
  CardImage,
  ContentCourse,
  ImageCardCourse,
  InfoCardCourse,
  Section,
} from "../style";
import { useCoursePresenter } from "../../dashboard/instrutor/cursos/presenter";

import { Link, useNavigate, useParams } from "react-router-dom";
import React from "react";
import Loadding from "../../../components/loadding/page";
import {
  Paragraph,
  ParagraphBold,
  Title,
} from "../../../components/Typography";
import Carousel from "react-multi-carousel";
import { Rate } from "rsuite";
import { FaCartShopping } from "react-icons/fa6";
const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

const Categoria: React.FC = observer(() => {
  const {
    resetStoreValues,
    courseFilter,
    getTitleCategoria,
    getCourseByCategoria,
    getIdCategoria,
    courseList,
    getFormatCurrent,
    loadding,
  } = useCoursePresenter();
  const navigate = useNavigate();
  const { id_categoria } = useParams();
  React.useEffect(() => {
    getCourseByCategoria(getIdCategoria(id_categoria as string));
  }, []);
  return (
    <>
      <FrontLayout>
        <Section>
          <Content>
            {loadding ? (
              <Loadding />
            ) : (
              <>
                <Title style={{ marginBottom: 8 }}>
                  {getTitleCategoria(id_categoria as string)}
                  {courseFilter && `(${courseFilter.length})`}
                </Title>
                <Paragraph size="17px" style={{ marginBottom: 8 }}>
                  Aproveite o aprendizado em casa sem um cronograma definido e
                  com um método fácil de seguir. Você define seu próprio ritmo.
                </Paragraph>
                {courseFilter && (
                  <Carousel responsive={responsive}>
                    {courseFilter.map((item, i) => (
                      <CardCourse>
                        <ImageCardCourse src={item?.img_url} />
                        <InfoCardCourse>
                          <Link
                            to={`/cursos/${item?.external_id}/detail`}
                            style={{ textDecoration: "none" }}
                          >
                            <ParagraphBold
                              size="14px"
                              style={{ marginRight: 10 }}
                            >
                              {item?.titulo}
                            </ParagraphBold>
                          </Link>
                          <Paragraph>{item?.users?.name}</Paragraph>
                          <Paragraph size="11px">
                            <strong>2.7</strong>{" "}
                            <Rate defaultValue={4} size="xs" color="yellow" />
                            (43)
                          </Paragraph>
                          <ParagraphBold color={theme.BACKGROUND.GREEN}>
                            {item?.price
                              ? `${getFormatCurrent(item?.price as number)} Kz`
                              : "Grátis"}
                          </ParagraphBold>
                          <ButtonPayCourse
                            onClick={() => {
                              navigate("/cursos/" + item?.id + "/detail");
                            }}
                          >
                            <FaCartShopping size={14} /> Adquirir Agora
                          </ButtonPayCourse>
                        </InfoCardCourse>
                      </CardCourse>
                    ))}
                  </Carousel>
                )}
              </>
            )}
          </Content>
        </Section>
      </FrontLayout>
    </>
  );
});
export default Categoria;
const Content = styled.div`
  width: 86%;
  margin: auto;
  @media ${device.laptopL} {
    width: 80%;
  }
  @media ${device.mobileL} {
    width: 96%;
  }
`;
