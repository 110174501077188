import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import Home from "./pages/front/home";
import { useNavigation } from "./stores/navigationStore";
import { useUserStore } from "./stores/userStore";
import Dashboard from "./pages/dashboard";
import CourseDetail from "./pages/front/cursos/CourseDetail";
import CourseFront from "./pages/front/cursos";
import Universidade from "./pages/front/university";
import TrainingCenter from "./pages/front/training-center";
import Company from "./pages/front/company";
import Categoria from "./pages/front/categoria";
import LoginScreen from "./pages/front/auth/LoginScreen";
import SignUpScreen from "./pages/front/auth/SignUpScreen";
import Teach from "./pages/front/teach";

const AppRoutes = () => {
  const { history } = useNavigation();
  const { init, isLoggedIn } = useUserStore();

  React.useEffect(() => {
    init();
  }, []);

  return (
    <Router>
      <Routes>
        <Route index element={<Home />} path="/" />
        <Route path="/categoria/:id_categoria/*" element={<Categoria />} />
        <Route element={<TrainingCenter />} path="/training-center" />
        <Route element={<Universidade />} path="/university" />
        <Route element={<Company />} path="/company" />
        <Route element={<Teach />} path="/teach" />
        <Route element={<LoginScreen />} path="/login" />
        <Route element={<SignUpScreen />} path="/signup" />
        <Route path="/dashboard/*" element={<Dashboard />} />
        <Route path="/cursos/:id_course/*" element={<CourseFront />} />
      </Routes>
    </Router>
  );
};

export default AppRoutes;
